import React, { useCallback, useContext, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import style from "../AdminPage/AdminPage.module.css";
import newStyle from "./NewUI.module.css";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";
import { AccessLevelType, UploadDocumentRequestType } from "../../types/types";

type DataUploadMenuType = {
  refreshData: () => void;
  accessLevels: AccessLevelType[];
  endpointName: string;
  titel: string;
  type: "normal" | "legal";
};

const DataUploadMenu: React.FC<DataUploadMenuType> = ({
  refreshData,
  accessLevels,
  endpointName,
  titel,
  type,
}) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const state = useContext(StoreContext);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [crawlerUrl, setCrawlerUrl] = useState<string>("");
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean | undefined>(
    undefined
  );
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFiles(acceptedFiles);
  }, []);

  const sendFiles = async (formData: FormData) => {
    try {
      const response: UploadDocumentRequestType = await axios.post(
        `${serverURL}/${siteId}/${endpointName}`,
        formData
      );
      console.log("Files uploaded successfully:", response.data);
      await refreshData();
      setSelectedFiles([]);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
    }
  };

  const sendUrl = async (url: string) => {
    try {
      const response: UploadDocumentRequestType = await axios.post(
        `${serverURL}/${siteId}/upload-url`,
        {
          url,
          accessLevel: selectedAccessLevel,
        }
      );
      console.log("URL submitted successfully:", response.data);
      setUploadSuccessful(response.data.success);
      await refreshData();
      setCrawlerUrl("");
      setLoading(false);
    } catch (error) {
      console.error("Error submitting URL:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(false);

    if (selectedFiles.length > 0 && crawlerUrl) {
      setError(true);
      setErrorMessage("Please either upload files or enter a URL, not both.");
      return;
    }

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      if (type === "normal") {
        formData.append("accessLevel", selectedAccessLevel.toString());
      }
      setLoading(true);
      await sendFiles(formData);
    } else if (crawlerUrl) {
      setLoading(true);
      await sendUrl(crawlerUrl);
    } else {
      setError(true);
      setErrorMessage("No files or URL provided.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
      }}
    >
      <h1 style={{ color: state.store.config.colorPrimary }}>{titel}</h1>
      <form className={style.formWrapper} onSubmit={handleSubmit}>
        <div
          {...getRootProps()}
          style={{
            border: `2px dashed ${state.store.config.colorPrimary}`,
            padding: "20px",
            textAlign: "center",
            width: "90%",
          }}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <div>
          {selectedFiles.length !== 0 ? (
            selectedFiles.map((file: File, index: number) => (
              <p key={index}>{file.name}</p>
            ))
          ) : (
            <p>Keine Dateien ausgewählt</p>
          )}
        </div>
        {type === "normal" && (
          <>
            <input
              className={newStyle.newChatBtn}
              style={{ padding: 5, height: 30, width: "50%" }}
              placeholder="Enter URL here:"
              value={crawlerUrl}
              onChange={(e) => setCrawlerUrl(e.currentTarget.value)}
            />
            <select
              id="selectDropdown"
              value={selectedAccessLevel}
              onChange={(e) =>
                setSelectedAccessLevel(Number(e.currentTarget.value))
              }
              className={newStyle.llmSelect}
            >
              {accessLevels.length !== 0 ? (
                accessLevels.map(
                  (accessLevelItem: AccessLevelType, index: number) => (
                    <option key={index} value={accessLevelItem.access_level}>
                      {accessLevelItem.access_level_name}
                    </option>
                  )
                )
              ) : (
                <option value={0}>Option 1</option>
              )}
            </select>
          </>
        )}

        <button
          disabled={
            accessLevels.length === 0 ||
            loading ||
            (crawlerUrl.length === 0 && selectedFiles.length === 0)
          }
          style={
            accessLevels.length !== 0 || selectedFiles.length !== 0 || !loading
              ? {
                  color: state.store.config.colorPrimary,
                  borderColor: state.store.config.colorPrimary,
                  cursor: "pointer",
                }
              : {}
          }
          className={style.submitBtn}
          type="submit"
        >
          {loading ? "Uploading in process..." : "Hochladen"}
        </button>
      </form>
      <div>
        {uploadSuccessful === undefined ? (
          <></>
        ) : uploadSuccessful ? (
          <p>Uploaded successfuly</p>
        ) : (
          <p>Upload unsuccessful</p>
        )}
      </div>
      {error ? <div>{errorMessage}</div> : <></>}
    </div>
  );
};

export default DataUploadMenu;
