//DATA UPLOAD LOGIC
import style from "../GruenUI/NewUI.module.css";
import DataUploadMenu from "../GruenUI/DataUploadMenu";
import DocumentsList from "../GruenUI/DocumentsList";
import React from "react";
import { AccessLevelType } from "../../types/types";

// const sidebarItemsAll = [
//   "Dokumentenliste",
//   "Dokumente hochladen",
//   "Rechts dokumente hochladen",
// ];

type DataUploadElementsType = {
  selectedSidebarItem: string;
  refreshData: () => void;
  accessLevels: AccessLevelType[];
  sources: {
    doc_name: string;
    access_level: number;
  }[];
  siteId: string;
};

const DataUploadElements: React.FC<DataUploadElementsType> = ({
  selectedSidebarItem,
  refreshData,
  accessLevels,
  sources,
  siteId,
}) => {
  if (siteId && siteId === "bvmw") {
    return (
      <div className={style.chatWrapper}>
        {selectedSidebarItem === "Dokumentenliste" ? (
          <DocumentsList
            accessLevels={accessLevels}
            sources={sources}
            refreshData={refreshData}
          />
        ) : (
          <DataUploadMenu
            refreshData={refreshData}
            accessLevels={accessLevels}
            endpointName="upload"
            titel="Dokumente für Wissensbasis hochladen"
            type="normal"
          />
        )}
      </div>
    );
  } else {
    return (
      <div className={style.chatWrapper}>
        {selectedSidebarItem === "Dokumentenliste" ? (
          <DocumentsList
            accessLevels={accessLevels}
            sources={sources}
            refreshData={refreshData}
          />
        ) : selectedSidebarItem === "Dokumente hochladen" ? (
          <DataUploadMenu
            refreshData={refreshData}
            accessLevels={accessLevels}
            endpointName="upload"
            titel="Dokumente für Wissensbasis hochladen"
            type="normal"
          />
        ) : (
          <DataUploadMenu
            refreshData={refreshData}
            accessLevels={accessLevels}
            endpointName="upload-legal-document"
            titel="Rechts Dokumente für Wissensbasis hochladen"
            type="legal"
          />
        )}
      </div>
    );
  }
};

export default DataUploadElements;
