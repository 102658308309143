import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { StoreContext } from "../../data/Context";
import AiMessage from "../common/AiMessage";
import Sidebar from "../Sidebar/Sidebar";
import style from "./NewUI.module.css";
// import { testDoc } from "../../data/testDoc";
import { TokenTextSplitter } from "@langchain/textsplitters";
import {
  analyse_and_create_required_fields_legal_docs_chain,
  analyseForFinalAnswerChain,
  askForFieldsAfterAnalyseChain,
  askForRequiredFieldsChain,
  askForRequiredFieldsInitialChain,
  checkIfEgoughInformationChain,
  combineForFinalAnswerChain,
  combineObjectsChain,
  conversationAfterWorkflowPredictionChain,
  getRequiredFieldsProgressiveChain,
  legal_standalone_question_chain,
} from "../../langchain/newWorkflowChains";
import { saveMessageInDB, sendAnswerToDb } from "../../langchain/chains";
import {
  ChatType,
  CreateNewChatRequestType,
  FetchAllChatsRequestType,
  FetchUserDataRequestType,
  GetContextRequestType,
} from "../../types/types";
/* eslint-disable react-hooks/exhaustive-deps */
type RequiredFieldsType =
  | {
      [key: string]: any;
    }
  | undefined;
const ChatBotNewWorkflow = () => {
  const initialMessage: ChatType = {
    chat_id: "123",
    chat_name: "Chat Room",
    user_id: "user1",
    chat_content: [],
    last_changed: "",
    role: "user",
  };
  const { siteId } = useParams();

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const state = useContext(StoreContext);
  const [userInput, setUserInput] = useState("");
  const [chatsArray, setChatsArray] = useState<ChatType[]>([]);
  const [currentChat, setCurrentChat] = useState(initialMessage);
  const [generatingInProgress, setGeneratingInProgress] =
    useState<boolean>(false);
  const [requiredFields, setRequiredFields] =
    useState<RequiredFieldsType>(undefined);
  const [contextDocument, setContextDocument] = useState("");
  const [resultCompleted, setResultCompleted] = useState<boolean>(false);
  const [informationAnalyseCounter, setInformationAnalyseCounter] =
    useState<number>(0);

  const fetchUserData = async (): Promise<any> => {
    try {
      const response: FetchUserDataRequestType = await axios.post(
        `${serverURL}/${siteId}/get-user-data`,
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const userData = response.data.user_data;
      if (userData) {
        state.setStore((prevState) => ({
          ...prevState,
          userId: userData.id,
          email: userData.email,
          username: userData.username,
          chatList: userData.chats_id,
          chatInUse: userData.lastUsedChat,
          group_id: userData.group_id,
          group_role: userData.group_role,
          template_in_use: userData.template_in_use,
        }));
      }
      return response.data.user_data;
    } catch (error) {
      console.log(error);
      navigate(`/${siteId}/`);
    }
  };

  const selectChat = (chat_id: string) => {
    setRequiredFields(undefined);
    const selectedChat = chatsArray.find((chat) => chat.chat_id === chat_id);
    selectedChat
      ? setCurrentChat(selectedChat)
      : console.log("SELECT CHAT ERROR");
  };

  const fetchAllChats = async () => {
    try {
      const response: FetchAllChatsRequestType = await axios.post(
        `${serverURL}/${siteId}/get-all-chats`,
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );

      const chatsArr = response.data.chats_array;

      if (chatsArr.length > 0) {
        setChatsArray(chatsArr);
        setCurrentChat(chatsArr[0]);
      } else {
        createChat();
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  useEffect(() => {
    if (state.store.userId === 0) {
      fetchUserData();
    }

    if (state.store.userId !== 0) {
      fetchAllChats();
      //   setIsLoading(false);
    }
  }, [state.store.userId]);

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const updateChatName = async (chatName: string) => {
    const payload = {
      chat_id: currentChat.chat_id,
      chat_name: chatName,
    };
    try {
      await axios.post(`${serverURL}/${siteId}/update-chat-name`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      });
    } catch (error) {
      console.error("ERROR UPDATE CHAT NAME - " + error);
    }
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const createChat = async () => {
    await deleteEmptyChats();
    let chatName = userInput;
    if (userInput === "") {
      chatName = "New Chat";
    }
    const payload = {
      question: chatName,
    };
    try {
      // setIsLoading(true)
      const result: CreateNewChatRequestType = await axios.post(
        `${serverURL}/${siteId}/create-new-chat`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const newChatObj = result.data.new_chat_obj;
      setCurrentChat(newChatObj);
      fetchUserData();
      fetchAllChats();
      // setIsLoading(false)
      return newChatObj.chat_id;
    } catch (error) {
      console.log("Create Chat error - " + error);
    }
  };

  const deleteEmptyChats = async () => {
    try {
      await axios.get(`${serverURL}/${siteId}/delete-empty-chats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      });
    } catch (error) {}
  };

  const splitContextDocument = async (context: string) => {
    const textSplitter = new TokenTextSplitter({
      chunkSize: 3500,
      chunkOverlap: 0,
    });

    const texts = await textSplitter.splitText(context);
    return texts;
  };

  const getContext = async () => {
    const standaloneQuestion: { question: string } =
      await legal_standalone_question_chain.invoke({
        question: currentChat.chat_content[0].text,
        information: JSON.stringify(requiredFields),
      });
    const context: GetContextRequestType = await axios.post(
      `${serverURL}/${siteId}/get-context-legal-documents`,
      { question: standaloneQuestion.question },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      }
    );
    setContextDocument(context.data.context);
    return context.data.context;
  };

  const analyseRelevantDocCreateFields = async (
    question: string,
    aiMessageId: string
  ) => {
    const newRequiredFields: object =
      await analyse_and_create_required_fields_legal_docs_chain.invoke({
        question: question,
      });
    setRequiredFields((prevState) => {
      return {
        ...newRequiredFields,
      };
    });

    const response = await askForRequiredFieldsInitialChain.stream({
      requiredInformation: JSON.stringify(newRequiredFields),
    });

    const chunks = [];

    for await (const chunk of response) {
      chunks.push(chunk);
      setCurrentChat((prevCurrentChat) => {
        const updatedChatContent = [...prevCurrentChat.chat_content];
        const lastIndex = updatedChatContent.length - 1;

        updatedChatContent[lastIndex] = {
          ...updatedChatContent[lastIndex],
          text: (updatedChatContent[lastIndex].text || "") + chunk,
        };

        return { ...prevCurrentChat, chat_content: updatedChatContent };
      });
    }
    const combinedChunks = chunks?.join("");

    await sendAnswerToDb(
      combinedChunks,
      currentChat.chat_id,
      siteId,
      aiMessageId,
      "None"
    );
  };

  const processingUserInformation = async (question: string) => {
    const response: { [key: string]: string } =
      await getRequiredFieldsProgressiveChain.invoke({
        requiredInformation: JSON.stringify(requiredFields),
        question: question,
      });

    setRequiredFields((prevState) => {
      return {
        ...response,
      };
    });

    return response;
  };

  const collectingUserInformation = async (
    fieldsToAsk: {
      [key: string]: string;
    },
    aiMessageId: string
  ) => {
    const response = await askForRequiredFieldsChain.stream({
      requiredInformation: JSON.stringify(fieldsToAsk),
    });

    const chunks = [];

    for await (const chunk of response) {
      chunks.push(chunk);
      setCurrentChat((prevCurrentChat) => {
        const updatedChatContent = [...prevCurrentChat.chat_content];
        const lastIndex = updatedChatContent.length - 1;

        updatedChatContent[lastIndex] = {
          ...updatedChatContent[lastIndex],
          text: (updatedChatContent[lastIndex].text || "") + chunk,
        };

        return { ...prevCurrentChat, chat_content: updatedChatContent };
      });
    }
    const combinedChunks = chunks?.join("");

    await sendAnswerToDb(
      combinedChunks,
      currentChat.chat_id,
      siteId,
      aiMessageId,
      "None"
    );
  };

  const collectingUserInformationAfterAnalyse = async (
    fieldsToAsk: {
      [key: string]: string;
    },
    aiMessageId: string
  ) => {
    const response = await askForFieldsAfterAnalyseChain.stream({
      requiredInformation: JSON.stringify(fieldsToAsk),
    });

    const chunks = [];

    for await (const chunk of response) {
      chunks.push(chunk);
      setCurrentChat((prevCurrentChat) => {
        const updatedChatContent = [...prevCurrentChat.chat_content];
        const lastIndex = updatedChatContent.length - 1;

        updatedChatContent[lastIndex] = {
          ...updatedChatContent[lastIndex],
          text: (updatedChatContent[lastIndex].text || "") + chunk,
        };

        return { ...prevCurrentChat, chat_content: updatedChatContent };
      });
    }
    const combinedChunks = chunks?.join("");

    await sendAnswerToDb(
      combinedChunks,
      currentChat.chat_id,
      siteId,
      aiMessageId,
      "None"
    );
  };

  const checkIfEnoughInformationForCase = async (fieldsToAsk: {
    [key: string]: string;
  }) => {
    const context: string = await getContext();
    const chunks = await splitContextDocument(context);

    let isEnough: boolean = true;
    let newRequiredFieldsArray: any[] = [];

    for (const text of chunks) {
      const res: any = await checkIfEgoughInformationChain.invoke({
        requiredFields: JSON.stringify(fieldsToAsk),
        legalDocument: text,
      });

      if (res.hasOwnProperty("enough") && res.enough === false) {
        isEnough = false;
      }

      if (res.hasOwnProperty("requiredFields")) {
        newRequiredFieldsArray.push(res.requiredFields);
      }
    }

    if (!isEnough) {
      setInformationAnalyseCounter(1);
      const resCombine: RequiredFieldsType = (await combineObjectsChain.invoke({
        objects: JSON.stringify(newRequiredFieldsArray),
      })) as RequiredFieldsType;
      console.log(resCombine);
      setRequiredFields((prevState) => {
        return {
          ...resCombine,
        };
      });
      return resCombine;
    }
  };

  const generateFinalPrediction = async (aiMessageId: string) => {
    let partialResults: string[] = [];
    const legalDocumentChunks = await splitContextDocument(contextDocument);

    for await (const text of legalDocumentChunks) {
      const partialRes: string = await analyseForFinalAnswerChain.invoke({
        requiredFields: JSON.stringify(requiredFields),
        legalDocument: text,
      });
      partialResults.push(partialRes);
    }

    const response = await combineForFinalAnswerChain.stream({
      resultsArray: JSON.stringify(partialResults),
    });

    const chunks = [];

    for await (const chunk of response) {
      chunks.push(chunk);
      setCurrentChat((prevCurrentChat) => {
        const updatedChatContent = [...prevCurrentChat.chat_content];
        const lastIndex = updatedChatContent.length - 1;

        updatedChatContent[lastIndex] = {
          ...updatedChatContent[lastIndex],
          text: (updatedChatContent[lastIndex].text || "") + chunk,
        };

        return { ...prevCurrentChat, chat_content: updatedChatContent };
      });
    }
    const combinedChunks = chunks?.join("");

    await sendAnswerToDb(
      combinedChunks,
      currentChat.chat_id,
      siteId,
      aiMessageId,
      "None"
    );
    setResultCompleted(true);
  };

  const conversationAfterFinalPrediction = async (aiMessageId: string) => {
    const response = await conversationAfterWorkflowPredictionChain.stream({
      conversationHistory: JSON.stringify(currentChat.chat_content),
      requiredFields: JSON.stringify(requiredFields),
      question: userInput,
    });

    const chunks = [];

    for await (const chunk of response) {
      chunks.push(chunk);
      setCurrentChat((prevCurrentChat) => {
        const updatedChatContent = [...prevCurrentChat.chat_content];
        const lastIndex = updatedChatContent.length - 1;

        updatedChatContent[lastIndex] = {
          ...updatedChatContent[lastIndex],
          text: (updatedChatContent[lastIndex].text || "") + chunk,
        };

        return { ...prevCurrentChat, chat_content: updatedChatContent };
      });
    }
    const combinedChunks = chunks?.join("");

    await sendAnswerToDb(
      combinedChunks,
      currentChat.chat_id,
      siteId,
      aiMessageId,
      "None"
    );
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const progressConversation = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    questionInput: string
  ) => {
    event.preventDefault();
    setGeneratingInProgress(true);
    try {
      if (currentChat.chat_name === "New Chat") {
        updateChatName(questionInput);
        setCurrentChat({ ...currentChat, chat_name: questionInput });
      }
      setUserInput("");

      state.setStore({
        ...state.store,
        chatInUse: currentChat.chat_id,
      });

      const aiMessageId = v4();
      const humanMessageId = v4();

      const newMessages = [
        ...currentChat.chat_content,
        {
          text: questionInput,
          sender: "human",
          chat_message_id: humanMessageId,
        },
        { text: "", sender: "ai", chat_message_id: aiMessageId },
      ];

      const updatedMessagesArray = {
        ...currentChat,
        chat_content: newMessages,
      };

      setCurrentChat(updatedMessagesArray);
      if (siteId) {
        await saveMessageInDB(
          currentChat.chat_id,
          questionInput,
          humanMessageId,
          siteId
        );
      }
      if (resultCompleted) {
        await conversationAfterFinalPrediction(aiMessageId);
      } else {
        if (requiredFields === undefined) {
          await analyseRelevantDocCreateFields(questionInput, aiMessageId);
        } else {
          let hasUndefinedValue = false;

          for (const key in requiredFields) {
            if (
              requiredFields.hasOwnProperty(key) &&
              requiredFields[key] === "undefined"
            ) {
              hasUndefinedValue = true;
              break;
            }
          }

          if (hasUndefinedValue) {
            const res: { [key: string]: string } =
              await processingUserInformation(questionInput);

            hasUndefinedValue = false;

            for (const key in res) {
              if (res.hasOwnProperty(key) && res[key] === "undefined") {
                hasUndefinedValue = true;
                break;
              }
            }

            if (hasUndefinedValue) {
              await collectingUserInformation(res, aiMessageId);
            } else {
              hasUndefinedValue = false;
              if (informationAnalyseCounter < 1) {
                const analyseRes = await checkIfEnoughInformationForCase(res);
                for (const key in analyseRes) {
                  if (
                    analyseRes &&
                    analyseRes.hasOwnProperty(key) &&
                    analyseRes[key] === "undefined"
                  ) {
                    hasUndefinedValue = true;
                    break;
                  }
                }

                if (
                  hasUndefinedValue &&
                  analyseRes &&
                  informationAnalyseCounter < 1
                ) {
                  await collectingUserInformationAfterAnalyse(
                    analyseRes,
                    aiMessageId
                  );
                } else {
                  await generateFinalPrediction(aiMessageId);
                }
              } else {
                await generateFinalPrediction(aiMessageId);
              }
            }
          } else {
            await generateFinalPrediction(aiMessageId);
          }
        }
      }

      //   await fetchUserData();
      setGeneratingInProgress(false);
    } catch (error) {
      setGeneratingInProgress(false);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT R
  //---------------------------------------------------------------------------------------------------------------------------------------------------

  const setRatingToMessage = async (
    rating: string,
    messageId: string | undefined
  ) => {
    const index = currentChat.chat_content.findIndex(
      (message) => message.chat_message_id === messageId
    );

    if (
      (currentChat.chat_content[index].rating === "like" &&
        rating === "like") ||
      (currentChat.chat_content[index].rating === "dislike" &&
        rating === "dislike")
    ) {
      return;
    }

    let intent: string | undefined = "";
    let question: string = "";
    let answer: string = "";
    if (index !== -1 && index > 0) {
      answer = currentChat.chat_content[index].text;
      question = currentChat.chat_content[index - 1].text;
      intent = currentChat.chat_content[index].intent;
    } else {
      console.error("Message not found or no previous question.");
    }
    try {
      setChatsArray((prevChatsArray) =>
        prevChatsArray.map((chat) =>
          chat.chat_id === currentChat.chat_id
            ? {
                ...chat,
                chat_content: chat.chat_content.map((message) =>
                  message.chat_message_id === messageId
                    ? { ...message, rating: rating }
                    : message
                ),
              }
            : chat
        )
      );

      setCurrentChat((prevCurrentChat) => {
        return {
          ...prevCurrentChat,
          chat_content: prevCurrentChat.chat_content.map((message) =>
            message.chat_message_id === messageId
              ? { ...message, rating: rating }
              : message
          ),
        };
      });

      await axios.post(
        `${serverURL}/${siteId}/set-message-rating`,
        {
          chat_id: currentChat.chat_id,
          rating: rating,
          message_id: messageId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );

      if (rating === "like") {
        await axios.post(
          `${serverURL}/${siteId}/user-intents-on-like-analyse`,
          {
            question: question,
            answer: answer,
            intent: intent,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + localStorage.getItem(`${siteId}_access_token`),
            },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setCommentToMessage = async (
    comment: string | undefined,
    messageId: string | undefined
  ) => {
    try {
      setChatsArray((prevChatsArray) =>
        prevChatsArray.map((chat) =>
          chat.chat_id === currentChat.chat_id
            ? {
                ...chat,
                chat_content: chat.chat_content.map((message) =>
                  message.chat_message_id === messageId
                    ? { ...message, comment: comment }
                    : message
                ),
              }
            : chat
        )
      );

      setCurrentChat((prevCurrentChat) => {
        return {
          ...prevCurrentChat,
          chat_content: prevCurrentChat.chat_content.map((message) =>
            message.chat_message_id === messageId
              ? { ...message, comment: comment }
              : message
          ),
        };
      });

      await axios.post(
        `${serverURL}/${siteId}/set-message-comment`,
        {
          chat_id: currentChat.chat_id,
          comment: comment,
          message_id: messageId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
    } catch (error) {}
  };

  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.sidebarWrapper} style={{ paddingLeft: 0 }}>
        <button className={style.newChatBtn} onClick={createChat}>
          New Chat
        </button>
        <Sidebar
          chatList={chatsArray && chatsArray}
          currentChatId={currentChat ? currentChat.chat_id : undefined}
          selectChat={selectChat}
        />
      </div>
      <div className={style.chatWrapper}>
        <div className={style.chatMessagesWrapper}>
          {currentChat.chat_content.map((message, index) =>
            message.sender === "ai" ? (
              <AiMessage
                key={index}
                message={message}
                index={index}
                currentChat={currentChat}
                setRatingToMessage={setRatingToMessage}
                setCommentToMessage={setCommentToMessage}
                generatingInProgress={generatingInProgress}
              />
            ) : (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-end" }}
              >
                <div key={index} className={`speech speech-human`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <form id="form" className={style.inputWrapper}>
          <input
            name="user-input"
            type="text"
            id="user-input"
            required
            value={userInput}
            onChange={(e) => setUserInput(e.currentTarget.value)}
            placeholder="Schreibe eine Nachricht"
            style={{
              borderBottomLeftRadius: 50,
              borderTopLeftRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          />
          <button
            id="submit-btn"
            disabled={!userInput.trim() || generatingInProgress}
            className="submit-btn"
            onClick={(e) => progressConversation(e, userInput)}
            style={{
              borderBottomRightRadius: 50,
              borderTopRightRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          >
            <img src="/images/send.svg" className="send-btn-icon" alt="" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatBotNewWorkflow;
