import React, { useContext, useEffect } from "react";
import style from "./NewUI.module.css";
import { StoreContext } from "../../data/Context";
import UserGroupItem from "../UserPage/UserGroupItem";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FetchUserDataRequestType } from "../../types/types";

const Group = () => {
  const state = useContext(StoreContext);
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const navigate = useNavigate();

  const fetchUserData = async () => {
    try {
      const response: FetchUserDataRequestType = await axios.post(
        `${serverURL}/${siteId}/get-user-data`,
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const userData = response.data.user_data;
      if (userData) {
        state.setStore((prevState) => ({
          ...prevState,
          userId: userData.id,
          email: userData.email,
          username: userData.username,
          chatList: userData.chats_id,
          chatInUse: userData.lastUsedChat,
          group_id: userData.group_id,
          group_role: userData.group_role,
          template_in_use: userData.template_in_use,
        }));
      }
      return response.data.user_data;
    } catch (error) {
      console.log(error);
      navigate(`/${siteId}/`);
    }
  };

  useEffect(() => {
    if (state.store.userId === 0) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.chatWrapper}>
        <>
          {state.store.group_id !== "" ? (
            <UserGroupItem groupId={state.store.group_id} />
          ) : (
            <div>You have no group</div>
          )}
        </>
      </div>
    </div>
  );
};

export default Group;
