// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Heavy.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Gilroy-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/Gilroy-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gilroy';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
    font-weight: 600;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,+DAAuD;IACvD,oBAAoB;IACpB,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,+DAAuD;IACvD,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,+DAAsD;IACtD,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,+DAAyD;IACzD,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,+DAA0D;IAC1D,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Gilroy';\n    src: url('./fonts/Gilroy-Light.ttf') format('truetype');\n    font-weight: lighter;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'Gilroy';\n    src: url('./fonts/Gilroy-Heavy.ttf') format('truetype');\n    font-weight: 800;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'Gilroy';\n    src: url('./fonts/Gilroy-Bold.ttf') format('truetype');\n    font-weight: 700;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'Gilroy';\n    src: url('./fonts/Gilroy-Regular.ttf') format('truetype');\n    font-weight: 500;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'Gilroy';\n    src: url('./fonts/Gilroy-SemiBold.ttf') format('truetype');\n    font-weight: 600;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
