/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import style from "./AdminPage.module.css";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import DocumentItems from "./DocumentItems";
import { useParams } from "react-router-dom";
import {
  AccessLevelType,
  CreateNewAccessLevelRequestType,
  DeleteFileRequestType,
  GetAccessLevelsRequestType,
  GetSourcesRequestType,
  RAGDocumentType,
} from "../../types/types";

// export type DocumentType = {
//   doc_name: string;
//   access_level: number;
// };

const DataUploadPage = () => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();

  const [accessLevels, setAccessLevels] = useState<AccessLevelType[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(0);
  const [createAccessLevelGroupMode, setCreateAccessLevelGroupMode] =
    useState<boolean>(false);
  const [newAccessGroupName, setNewAccessGroupName] = useState<string>("");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFiles(acceptedFiles);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("accessLevel", selectedAccessLevel.toString());

      try {
        const response = await axios.post(
          "http://localhost:8000/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Files uploaded successfully:", response.data);
        await refreshData();
        setSelectedFiles([]);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    } else {
      console.log("No files selected");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const [sources, setSources] = useState<RAGDocumentType[]>([]);

  useEffect(() => {
    refreshData();
  }, []);

  const getSources = async () => {
    try {
      const response: GetSourcesRequestType = await axios.get(
        `${serverURL}/${siteId}/sources`
      );

      setSources(response.data);
    } catch (error) {
      console.error("There was an error fetching the sources!", error);
    }
  };

  const deleteFileFromDatabase = async (sourceValue: string) => {
    try {
      const res: DeleteFileRequestType = await axios.post(
        `${serverURL}/${siteId}/delete-file`,
        {
          source: sourceValue,
        }
      );
      console.log("Deleting file from database " + res.data);
    } catch (error) {
      console.log("Error during deleting file from database");
    }

    await refreshData();
  };

  const getAccessLevels = async () => {
    try {
      const res: GetAccessLevelsRequestType = await axios.get(
        `${serverURL}/${siteId}/get-access-levels`
      );
      setAccessLevels(res.data.result);
    } catch (error) {
      console.log("Error while getAccessLevelsNames " + error);
    }
  };

  const refreshData = async () => {
    await getSources();
    await getAccessLevels();
  };

  const createNewAccessLevelGroup = async () => {
    try {
      const res: CreateNewAccessLevelRequestType = await axios.post(
        `${serverURL}/${siteId}/create-new-access-level`,
        {
          access_level_name: newAccessGroupName,
          access_level_value: accessLevels.length,
        }
      );
      console.log(res.data.result);
      setCreateAccessLevelGroupMode(!createAccessLevelGroupMode);
      await refreshData();
    } catch (error) {
      console.error("Create new access level group error - " + error);
    }
  };

  return (
    <div className={style.dataUploadContainer}>
      <h1>Upload Training Data</h1>
      <form className={style.formWrapper} onSubmit={handleSubmit}>
        <div
          {...getRootProps()}
          style={{
            border: "2px dashed orange",
            padding: "20px",
            textAlign: "center",
            width: "70%",
          }}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <div>
          {selectedFiles.length !== 0 ? (
            selectedFiles.map((file: File, index: number) => <p>{file.name}</p>)
          ) : (
            <p>No files selected</p>
          )}
        </div>
        <select
          id="selectDropdown"
          value={selectedAccessLevel}
          onChange={(e) =>
            setSelectedAccessLevel(Number(e.currentTarget.value))
          }
        >
          {accessLevels.length !== 0 ? (
            accessLevels.map(
              (accessLevelItem: AccessLevelType, index: number) => (
                <option key={index} value={accessLevelItem.access_level}>
                  {accessLevelItem.access_level_name}
                </option>
              )
            )
          ) : (
            <option value={0}>Option 1</option>
          )}
        </select>
        <button
          disabled={accessLevels.length === 0 || selectedFiles.length === 0}
          className={style.submitBtn}
          type="submit"
        >
          Upload
        </button>
      </form>
      <h2>Uploaded Files</h2>
      {accessLevels.length !== 0
        ? accessLevels.map(
            (accessLevelItem: AccessLevelType, index: number) => (
              <div key={index} className={style.documentItemParent}>
                <h3 key={index}>{accessLevelItem.access_level_name}</h3>
                <div className={style.sourcesWrapper}>
                  <DocumentItems
                    sources={sources.filter(
                      (doc: RAGDocumentType) =>
                        doc.access_level === accessLevelItem.access_level
                    )}
                    deleteFileFromDatabase={deleteFileFromDatabase}
                    accessLevels={accessLevels}
                    refreshData={refreshData}
                    type="admin"
                  />
                </div>
              </div>
            )
          )
        : "No access levels, please create one"}
      {!createAccessLevelGroupMode ? (
        <button
          className={style.createAccessLevelGroupButton}
          onClick={() =>
            setCreateAccessLevelGroupMode(!createAccessLevelGroupMode)
          }
        >
          Create access level group
        </button>
      ) : (
        <div className={style.createAccessLevelGroupInputBtnWrapper}>
          <input
            value={newAccessGroupName}
            onChange={(e) => setNewAccessGroupName(e.currentTarget.value)}
            className={style.createAccessLevelGroupInput}
            placeholder="Enter new name for access control group"
          />
          <button
            disabled={!newAccessGroupName}
            style={{ fontSize: 16 }}
            onClick={() => createNewAccessLevelGroup()}
          >
            Submit
          </button>
        </div>
      )}
      <h1 style={{ color: "transparent" }}>A</h1>
    </div>
  );
};

export default DataUploadPage;
