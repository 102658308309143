import React, { useContext, useState } from "react";
import style from "./AdminPage.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";
import { UserDataType } from "../../types/types";

type GroupUserItemType = {
  user: UserDataType;
  roles?: { role_name: string; role_available_docs: string[] }[] | undefined;
  usersRole: string;
  refreshData: () => void;
};

const GroupUserItem: React.FC<GroupUserItemType> = ({
  user,
  roles,
  usersRole,
  refreshData,
}) => {
  const state = useContext(StoreContext);

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>(usersRole);

  const handleSelect = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(e.currentTarget.value);
    setEditMode(!editMode);
    await updateUsersRole(e.currentTarget.value);
    refreshData();
  };

  const updateUsersRole = async (newRoleName: string) => {
    try {
      const res = await axios.post(`${serverURL}/${siteId}/update-users-role`, {
        user_id: user.id,
        new_role_name: newRoleName,
      });
      console.log("Update user s role - " + res.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={style.uploadedFileWrapper}
      style={{
        width: "30%",
        borderRightColor: state.store.config.colorPrimary,
      }}
    >
      <p style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <img style={{ height: 20 }} src="/images/circle-user.svg" alt="file" />

        <span>{user.group_role}:</span>
        <span>{user.username}</span>
      </p>
      <div className={style.documentItemRightPart}>
        {editMode ? (
          <select
            id="selectDropdown"
            value={selectedRole}
            onChange={(e) => handleSelect(e)}
          >
            {roles?.length !== 0 ? (
              roles?.map(
                (
                  roleItem: {
                    role_name: string;
                    role_available_docs: string[];
                  },
                  index: number
                ) => (
                  <option key={index} value={roleItem.role_name}>
                    {roleItem.role_name}
                  </option>
                )
              )
            ) : (
              <option value={0}>Option 1</option>
            )}
          </select>
        ) : (
          <button
            className={style.documentItemEditButton}
            onClick={() => setEditMode(!editMode)}
          >
            Edit
          </button>
        )}

        <button
          // onClick={() => deleteFileFromDatabase(doc.doc_name)}
          className={style.sourceDeleteButton}
        >
          <img
            style={{ height: 30 }}
            src="/images/clipboard.svg"
            alt="delete"
          />
        </button>
      </div>
    </div>
  );
};

export default GroupUserItem;
