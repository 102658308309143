import React, { useState } from "react";
import style from "./AdminPage.module.css";
import GroupItem from "./GroupItem";
import DataUploadPage from "./DataUploadPage";
import GroupCreationTagsAssigning from "./GroupCreationTagsAssigning";
import { Link, Route, Routes, useNavigate } from "react-router-dom";

const AdminPage = () => {
  const navigate = useNavigate();
  const adminKey = process.env.REACT_APP_ADMIN_KEY;
  const [passInput, setPassInput] = useState<string>("");

  const navigation = (path: string, data: { state: any }) => {
    // console.log('ADMIN PAGE = ' + JSON.stringify(data))
    navigate(path, data);
  };

  const checkAdminPassword = () => {
    if (passInput === adminKey) {
      setAccessGranted(true);
      setPassInput("");
    } else {
      setPassInput("");
    }
  };

  const [accessGranted, setAccessGranted] = useState<boolean>(false);
  return (
    <div className={style.container}>
      <div className={`chatbot-header`}>
        <div className="title">GRÜN GPT</div>
        <div className="username"></div>
      </div>
      <div className={style.sidebarAndContentWrapper}>
        {accessGranted ? (
          <div className={style.sidebar}>
            <button className={style.sidebarButton}>
              <Link to={"data-upload"}>Uploading training data</Link>
            </button>
            <button className={style.sidebarButton}>
              <Link to={"group-management"}>
                Assign tags and group creating
              </Link>
            </button>
            <button className={style.sidebarButton}>Prompt library</button>
          </div>
        ) : (
          <></>
        )}
        <div className={style.contentContainer}>
          {accessGranted ? (
            <Routes>
              <Route path="data-upload" element={<DataUploadPage />} />
              <Route
                path="group-management"
                element={<GroupCreationTagsAssigning navigate={navigation} />}
              />
              <Route path="group-item" element={<GroupItem />} />
            </Routes>
          ) : (
            <>
              <input
                name="user-input"
                type="text"
                id="user-input"
                required
                value={passInput}
                onChange={(e) => setPassInput(e.currentTarget.value)}
                style={{ width: "max-content", fontSize: 16 }}
              />
              <button onClick={checkAdminPassword} style={{ fontSize: 16 }}>
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
