import { PromptTemplate } from "@langchain/core/prompts";

const standalone_question_template = `Given some conversation history (if any) and a question, convert the question to a standalone question. 
  give direct standalone question. Standalone question must be in German language.
  Return asnwer as JSON with key standaloneQuestion with no preamble or explanation.
  conversation history: {conv_history}
  question: {question} 
  standalone question:
`;

const answer_template = `
You are a helpful German-speaking support bot that can answer a given question based on the context provided (if any).
Use the German language to formulate the answer.
Format the answer with HTML elements.
You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
{promptTemplateContent}

Always add sources for the information in answer in format:
<p><strong>Quelle:</strong>  <a target='_blank' rel='noreferrer' href='(source)' >source document name</a></p>

If you took information from context, add source to href as: {serverURL}/{siteId}/file-source/(source).
Remove "uploads/" from source link.

If you took information from internet, add source to href as just link to website.
In answer could be multiple links.
If there is no link, do not put them into answer.

Do not add html block in answer.

Follow answer structure if any.
Answer structure: {answer_structure}

Context: {context}
Conversation history: {conv_history}
Question: {question}
Answer:
`;

const answer_template_crawler = `
You are a helpful German-speaking support bot that can answer a given question based on the context provided (if any).
Use the German language to formulate the answer.
Format the answer with HTML elements.
You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
{promptTemplateContent}

Always add sources for the information in answer in format:
<p><strong>Quelle:</strong>  <a target='_blank' rel='noreferrer' href='(source)' >source document name</a></p>

If you took information from internet, add source to href as just link to website.
In answer could be multiple links.

Context: {context}
Conversation history: {conv_history}
Question: {question}
Answer:
`;

const answer_template_llama = `
You are a helpful German-speaking support bot that can answer a given question based on the context provided (if any).
Use the German language to formulate the answer.
Format the answer with HTML elements.
You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
{promptTemplateContent}

When generating answer use information from provided context.

Context: {context}
Conversation history: {conv_history}
Question: {question}
Answer:
`;
const standalone_question_prompt = PromptTemplate.fromTemplate(
  standalone_question_template
);

const answer_prompt = PromptTemplate.fromTemplate(answer_template);
const answer_prompt_llama = PromptTemplate.fromTemplate(answer_template_llama);
const answer_prompt_crawler = PromptTemplate.fromTemplate(
  answer_template_crawler
);
export {
  standalone_question_template,
  answer_template,
  standalone_question_prompt,
  answer_prompt,
  answer_prompt_llama,
  answer_prompt_crawler,
};
