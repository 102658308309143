// import { ChatOllama } from "@langchain/ollama";
import { answer_prompt, standalone_question_prompt } from "./template";
import {
  JsonOutputParser,
  StringOutputParser,
} from "@langchain/core/output_parsers";
import { ChatOpenAI } from "@langchain/openai";
import axios from "axios";
import { GetContextRequestType } from "../types/types";

const serverURL = process.env.REACT_APP_SERVER_URL;
const OpenAIKey = process.env.REACT_APP_OPENAI_API_KEY;
// const llamaBaseUrl = process.env.REACT_APP_LLAMA_BASE_URL;

// const llmLlama = new ChatOllama({
//   baseUrl: llamaBaseUrl,
//   model: "llama3.1",
// });

const llmOpenAI = new ChatOpenAI({
  apiKey: OpenAIKey,
  model: "gpt-4o",
});

const llmOpenAImini = new ChatOpenAI({
  apiKey: OpenAIKey,
  model: "gpt-4o-mini",
});

const llmOpenAIThreeFiveTurbo = new ChatOpenAI({
  apiKey: OpenAIKey,
  model: "gpt-3.5-turbo",
});

// const answer_chain_llama = answer_prompt
//   .pipe(llmLlama)
//   .pipe(new StringOutputParser());
const answer_chain_openai = answer_prompt
  .pipe(llmOpenAI)
  .pipe(new StringOutputParser());
const answer_chain_openai_mini = answer_prompt
  .pipe(llmOpenAImini)
  .pipe(new StringOutputParser());
const answer_chain_openai_three_five_turbo = answer_prompt
  .pipe(llmOpenAIThreeFiveTurbo)
  .pipe(new StringOutputParser());

// const standalone_question_chain_llama = standalone_question_prompt
//   .pipe(llmLlama)
//   .pipe(new JsonOutputParser());

const standalone_question_chain_openai = standalone_question_prompt
  .pipe(llmOpenAI)
  .pipe(new JsonOutputParser());

const standalone_question_chain_openai_mini = standalone_question_prompt
  .pipe(llmOpenAImini)
  .pipe(new JsonOutputParser());

const standalone_question_chain_openai_three_five_turbo =
  standalone_question_prompt
    .pipe(llmOpenAIThreeFiveTurbo)
    .pipe(new JsonOutputParser());

const getContext = async (
  question: string | undefined,
  currentChatId: string,
  accessLevelName: string[] | undefined,
  siteId: string | undefined,
  humanMessageId: string
) => {
  const payload = {
    question: question,
    chat_id: currentChatId,
    access_level_name: accessLevelName,
    human_message_id: humanMessageId,
  };
  try {
    const res: GetContextRequestType = await axios.post(
      `${serverURL}/${siteId}/get-context`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      }
    );
    return res.data.context;
  } catch (error) {
    console.log(error);
  }
};

const sendAnswerToDb = async (
  answer: string | undefined,
  chatId: string | number,
  siteId: string | undefined,
  aiMessageId: string,
  intent: string | undefined
) => {
  try {
    const payload = {
      chat_id: chatId,
      answer: answer,
      ai_message_id: aiMessageId,
      intent: intent,
    };
    await axios.post(`${serverURL}/${siteId}/add-response`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(`${siteId}_access_token`),
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const sendAnswerToDbCrawler = async (
  answer: string | undefined,
  chatId: string | number,
  siteId: string | undefined,
  aiMessageId: string
) => {
  try {
    const payload = {
      chat_id: chatId,
      answer: answer,
      ai_message_id: aiMessageId,
    };
    await axios.post(`${serverURL}/${siteId}/add-response`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(`${siteId}_access_token`),
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const saveMessageInDB = async (
  chatId: string,
  question: string,
  chatMessageId: string,
  siteId: string
) => {
  try {
    const payload = {
      chat_id: chatId,
      question: question,
      chat_message_id: chatMessageId,
    };

    await axios.post(`${serverURL}/${siteId}/save-message`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(`${siteId}_access_token`),
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  sendAnswerToDb,
  getContext,
  // answer_chain_llama,
  answer_chain_openai,
  answer_chain_openai_mini,
  answer_chain_openai_three_five_turbo,
  // llmLlama,
  llmOpenAI,
  llmOpenAIThreeFiveTurbo,
  llmOpenAImini,
  sendAnswerToDbCrawler,
  // standalone_question_chain_llama,
  standalone_question_chain_openai,
  standalone_question_chain_openai_mini,
  standalone_question_chain_openai_three_five_turbo,
  saveMessageInDB,
};
