/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import style from "./AdminPage.module.css";
import axios from "axios";
import AccessControlModal from "./AccessControlModal";
import { useLocation, useParams } from "react-router-dom";
import AddMembersModal from "./AddMembersModal";
import GroupUserItem from "./GroupUserItem";
import {
  AccessLevelType,
  GetAccessLevelsRequestType,
  GetAllUsersRequestType,
  GetGroupDataByIdRequestType,
  GroupType,
  UserDataType,
} from "../../types/types";

// export type UserType = {
//   email: string;
//   id: string;
//   role: string;
//   token: string;
//   username: string;
//   available_docs: string[];
//   group_id: string;
//   group_role: string;
// };

export type AccessLevelEditModeType = "toGroup" | "toRole" | undefined;

const GroupItem = () => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();

  const location = useLocation();
  const groupId = location.state || {};

  const [accessLevels, setAccessLevels] = useState<AccessLevelType[]>([]);
  const [groupItem, setGroupItem] = useState<GroupType | null>(null);
  const [users, setUsers] = useState<UserDataType[]>([]);
  const [showAccessLevelModal, setShowAccessLevelModal] =
    useState<boolean>(false);
  const [showAddMembersModal, setShowAddMembersModal] =
    useState<boolean>(false);

  const [searchString, setSearchString] = useState("");
  const [matchingUsers, setMatchingUsers] = useState(users);
  const [createGroupRoleMode, setCreateGroupRoleMode] =
    useState<boolean>(false);
  const [newGroupRoleName, setNewGroupRoleName] = useState<string>("");
  const [AccessLevelEditMode, setAccessLevelEditMode] =
    useState<AccessLevelEditModeType>("toGroup");
  const [roleToChange, setRoleToChange] = useState<string | undefined>(
    undefined
  );

  const handleSearch = (event: any) => {
    const value = event.target.value.toLowerCase();
    setSearchString(value);

    const matches = users.filter((user) =>
      user.username.toLowerCase().includes(value)
    );
    setMatchingUsers(matches);
  };

  // const handleAccessLevelModalOpen = (user: UserType) => {
  //     // setModalContent({username: user.username, availableDocs: user.available_docs, userId: user.id})
  //     setShowAccessLevelModal(true)
  // }

  const handleAccessLevelClose = () =>
    setShowAccessLevelModal(!showAccessLevelModal);
  const handleAddMemberClose = () =>
    setShowAddMembersModal(!showAddMembersModal);

  const getGroupDataById = async (groupId: string) => {
    try {
      const res: GetGroupDataByIdRequestType = await axios.post(
        `${serverURL}/${siteId}/get-group-data-by-id`,
        {
          group_id: groupId,
        }
      );
      setGroupItem(res.data.result);
    } catch (error) {
      console.error("Get group data by id error - " + error);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async () => {
    await getGroupDataById(groupId);
    await getAccessLevels();
    await getGroupUsers();
  };

  const getGroupUsers = async () => {
    try {
      const res: GetAllUsersRequestType = await axios.post(
        `${serverURL}/${siteId}/get-all-users-by-group-id`,
        {
          group_id: groupId,
        }
      );
      setUsers(res.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  // const updateModalContent = () => {
  //     const activeUser: UserType | undefined = users.find((user: UserType) => user.id === modalContent.userId)
  //     activeUser && setModalContent({ ...modalContent, availableDocs: activeUser.available_docs })
  // }

  const getAccessLevels = async () => {
    try {
      const res: GetAccessLevelsRequestType = await axios.get(
        `${serverURL}/${siteId}/get-access-levels`
      );
      setAccessLevels(res.data.result);
    } catch (error) {
      console.log("Error while getAccessLevelsNames " + error);
    }
  };

  // useEffect(() => {
  //     updateModalContent()
  // }, [users])

  const createNewRole = async () => {
    try {
      const res = await axios.post(
        `${serverURL}/${siteId}/create-new-role-in-group`,
        {
          group_id: groupId,
          role_name: newGroupRoleName,
        }
      );
      console.log("Create new role - " + res.data.result);
      setCreateGroupRoleMode(!createGroupRoleMode);
      setNewGroupRoleName("");
      await refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  const openAccessLevelModalToGroup = () => {
    setAccessLevelEditMode("toGroup");
    setShowAccessLevelModal(!showAccessLevelModal);
  };

  const openAccessLevelModalToRole = (role: string | undefined) => {
    console.log("role to change" + JSON.stringify(role));
    setRoleToChange(role);
    setAccessLevelEditMode("toRole");
    setShowAccessLevelModal(!showAccessLevelModal);
  };

  return (
    <div className={style.dataUploadContainer} style={{ gap: 30 }}>
      <h1>{groupItem?.group_name}</h1>
      <div className={style.groupDocsAndRolesWrapper}>
        <div>
          <h2>Available documents</h2>
          <div>
            {groupItem?.available_groups_of_documents.length !== 0 ? (
              groupItem?.available_groups_of_documents.map(
                (doc: string, index: number) => <p key={index}>{doc}</p>
              )
            ) : (
              <p>No docs Available</p>
            )}
            <button onClick={() => openAccessLevelModalToGroup()}>
              Edit available documents
            </button>
          </div>
        </div>
        <div>
          <h2>Group roles</h2>

          {groupItem?.group_roles.length !== 0 ? (
            groupItem?.group_roles.map(
              (
                groupRole: { role_name: string; role_available_docs: string[] },
                index: number
              ) => (
                <div
                  className={style.roleItem}
                  key={index}
                  onClick={() =>
                    openAccessLevelModalToRole(groupRole.role_name)
                  }
                >
                  {groupRole.role_name}
                </div>
              )
            )
          ) : (
            <div>No roles</div>
          )}

          {!createGroupRoleMode ? (
            <button
              className={style.createAccessLevelGroupButton}
              style={{ width: "100%" }}
              onClick={() => setCreateGroupRoleMode(!createGroupRoleMode)}
            >
              Create access level group
            </button>
          ) : (
            <div
              className={style.createAccessLevelGroupInputBtnWrapper}
              style={{ width: "100%" }}
            >
              <input
                value={newGroupRoleName}
                onChange={(e) => setNewGroupRoleName(e.currentTarget.value)}
                className={style.createAccessLevelGroupInput}
                placeholder="Enter new role name"
              />
              <button
                disabled={!newGroupRoleName}
                style={{ fontSize: 16 }}
                onClick={() => createNewRole()}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
      {/* ------------------------------------------------------------------------- */}
      <h2>{groupItem?.group_name} members</h2>
      <input
        type="text"
        value={searchString}
        onChange={handleSearch}
        placeholder="Search for a username..."
        style={{
          width: "30%",
          borderRadius: 20,
          borderWidth: 1,
          borderStyle: "solid",
        }}
      />
      <div className={style.sourcesWrapper}>
        {users.length !== 0 && searchString.length === 0
          ? users.map((user: UserDataType, index: number) => (
              <GroupUserItem
                user={user}
                key={index}
                roles={groupItem?.group_roles}
                usersRole={user.role}
                refreshData={refreshData}
              />
            ))
          : users.length !== 0 && matchingUsers.length !== 0
          ? matchingUsers.map((user: UserDataType, index: number) => (
              <GroupUserItem
                user={user}
                key={index}
                roles={groupItem?.group_roles}
                usersRole={user.role}
                refreshData={refreshData}
              />
            ))
          : "No Users found"}
        <button onClick={() => setShowAddMembersModal(!showAddMembersModal)}>
          Add members
        </button>

        <div>
          Date of creation:{" "}
          <strong>{groupItem?.creation_date.toLocaleString()}</strong>
        </div>
        <AccessControlModal
          accessLevelEditMode={AccessLevelEditMode}
          show={showAccessLevelModal}
          handleClose={handleAccessLevelClose}
          availableDocs={groupItem?.available_groups_of_documents}
          availableDocsRole={groupItem?.group_roles.find(
            (group) => group.role_name === roleToChange
          )}
          accessLevels={accessLevels}
          refreshData={refreshData}
          groupId={groupItem?._id}
        />
        <AddMembersModal
          show={showAddMembersModal}
          handleClose={handleAddMemberClose}
          groupId={groupId}
          usersInGroup={groupItem?.users}
          refreshData={refreshData}
        />
        <h1 style={{ color: "transparent" }}>A</h1>
      </div>
    </div>
  );
};

export default GroupItem;
