import "./App.css";
import Page from "./components/LoginPage/Page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContextState from "./data/Context";
import AdminPage from "./components/AdminPage/AdminPage";
import GruenUI from "./components/GruenUI/GruenUI";

function App() {
  return (
    <ContextState>
      <BrowserRouter>
        <Routes>
          <Route path="/:siteId/admin/*" element={<AdminPage />} />
          <Route path="/:siteId/*" element={<Page />} />
          <Route path="/:siteId/home/*" element={<GruenUI />} />
        </Routes>
      </BrowserRouter>
    </ContextState>
  );
}

export default App;
