import React, { useContext, useState } from "react";
import style from "./NewUI.module.css";
import {
  // llmLlama,
  llmOpenAI,
  // llmOpenAImini,
  // llmOpenAIThreeFiveTurbo,
} from "../../langchain/chains";
import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { read, utils } from "xlsx";
import Papa from "papaparse";
import DOMPurify from "dompurify";
import { StoreContext } from "../../data/Context";
import { ChatType } from "../../types/types";

export type DataRow = {
  [key: string]: string | number | null;
};

const SurveyAnalysis = () => {
  const initialMessage: ChatType = {
    chat_id: "123",
    chat_name: "Chat Room",
    user_id: "user1",
    chat_content: [],
    last_changed: "",
    role: "user",
  };

  const firstWindowText =
    "Bitte laden Sie Ihre Datei im XLSX-, XLS- oder CSV-Format hoch. Unterstützte Dateitypen gewährleisten eine reibungslose Verarbeitung. Klicken Sie auf die Schaltfläche, um eine Datei von Ihrem Gerät auszuwählen.";
  const secondWindowText =
    "Klicken Sie auf die Schaltfläche unten, um die Aufgabe zu starten. Stellen Sie sicher, dass alle erforderlichen Dateien und Details bereit sind. Sobald Sie beginnen, wird der Vorgang automatisch fortgesetzt.";

  const [data, setData] = useState<DataRow[]>([]);
  // const [selectedLLM, setSelectedLLM] = useState<string>("gpt-4o");
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [currentChat, setCurrentChat] = useState(initialMessage);
  const state = useContext(StoreContext);

  // const saveSelectedLLM = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   localStorage.setItem("lastUsedLLM", e.currentTarget.value);
  //   setSelectedLLM(e.currentTarget.value);
  // };

  // const getSavedSelectedLLM = async () => {
  //   const savedLLM = localStorage.getItem("lastUsedLLM");
  //   savedLLM && setSelectedLLM(savedLLM);
  // };

  // useEffect(() => {
  //   getSavedSelectedLLM();
  // });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && (file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const workbook = read(arrayBuffer, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json<DataRow>(worksheet);

        setData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    }
    if (file && file.name.endsWith(".csv")) {
      Papa.parse<DataRow>(file, {
        header: true,
        complete: (results) => {
          const sanitizedData = results.data.map(sanitizeData);
          setData(sanitizedData);
        },
      });
    }
  };

  const sanitizeData = (data: Record<string, any>) => {
    const sanitizedData: Record<string, any> = {};

    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value && value !== "\u0000" && value !== "") {
        sanitizedData[key] = value;
      }
    });

    return sanitizedData;
  };

  const analyzeData = async () => {
    setInProgress(true);

    let selectedLLMForAnalyse = llmOpenAI;

    setCurrentChat((prevCurrentChat) => ({
      ...prevCurrentChat,
      chat_content: [{ text: "", sender: "ai" }],
    }));

    const chunkSize = 20;

    const chunks = [];
    const overall = [];

    // First analyse, data provided in parts
    // --------------------------------------
    for (let i = 0; i < data.length; i += chunkSize) {
      const dataChunk = data.slice(i, i + chunkSize);

      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [{ text: `${i + 1} / ${data.length}`, sender: "ai" }],
      }));

      const prompt = `Analyze the following data: {dataChunk}`;
      const promptText = PromptTemplate.fromTemplate(prompt);

      const answer_chain_analysis = promptText
        .pipe(selectedLLMForAnalyse)
        .pipe(new StringOutputParser());

      try {
        const response = await answer_chain_analysis.invoke({
          dataChunk: JSON.stringify(dataChunk),
        });

        chunks.push(response);
      } catch (error) {
        console.error("Error during data analysis:", error);
        setInProgress(false);
        setCurrentChat((prevCurrentChat) => ({
          ...prevCurrentChat,
          chat_content: [
            ...prevCurrentChat.chat_content,
            { text: "Error analyzing data.", sender: "ai" },
          ],
        }));
      }
    }
    // Overall summary
    // ----------------------------
    const prompt = `Give me sumarisation of next results : {dataChunk}`;
    const promptText = PromptTemplate.fromTemplate(prompt);

    const answer_chain_openai_mini = promptText
      .pipe(selectedLLMForAnalyse)
      .pipe(new StringOutputParser());

    try {
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [{ text: "Summarisation", sender: "ai" }],
      }));
      const response = await answer_chain_openai_mini.invoke({
        dataChunk: JSON.stringify(chunks),
      });

      overall.push(response);
    } catch (error) {
      setInProgress(false);
      console.error("Error during data analysis:", error);
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [
          ...prevCurrentChat.chat_content,
          { text: "Error analyzing data.", sender: "ai" },
        ],
      }));
    }

    // Structurization
    // ----------------------------------

    const structurizationPrompt = `Structurize : {summarisation}
    Format the response with HTML elements.
    Return answer in German language.
    Do not add html code block in answer.
    Follow next answer structure:
    <h2>(Some title)</h2>
    <p>Short information from sumarization</p>
    <ul>Count highlights</ul>
    <p>Additional information</p>
    <p>Final results</p>
  `;
    const structurizationPromptText = PromptTemplate.fromTemplate(
      structurizationPrompt
    );

    const structurizationAnswer_chain_openai_mini = structurizationPromptText
      .pipe(selectedLLMForAnalyse)
      .pipe(new StringOutputParser());

    try {
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [{ text: "", sender: "ai" }],
      }));
      const response = await structurizationAnswer_chain_openai_mini.stream({
        summarisation: JSON.stringify(overall),
      });

      for await (const chunk of response) {
        setCurrentChat((prevCurrentChat) => {
          const updatedChatContent = [...prevCurrentChat.chat_content];
          const lastIndex = updatedChatContent.length - 1;

          updatedChatContent[lastIndex] = {
            ...updatedChatContent[lastIndex],
            text: (updatedChatContent[lastIndex].text || "") + chunk,
          };

          return {
            ...prevCurrentChat,
            chat_content: updatedChatContent,
          };
        });
      }
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      console.error("Error during data analysis:", error);
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [
          ...prevCurrentChat.chat_content,
          { text: "Error analyzing data.", sender: "ai" },
        ],
      }));
    }
  };
  return (
    <>
      <div className={style.chatWrapper}>
        <div
          className={style.chatMessagesWrapper}
          style={{ height: "100%", maxHeight: "100%" }}
        >
          {currentChat.chat_content.map((message, index) =>
            message.sender === "ai" ? (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-start" }}
              >
                <div className={style.gIconTemp}>G</div>
                <div key={index} className={`speech speech-ai`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message.text),
                      }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-end" }}
              >
                <div key={index} className={`speech speech-human`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className={style.tasksLibRightMenuWrapper}>
        <div className={style.tasksLibBox}>
          <p style={{ fontSize: 15 }}>{firstWindowText}</p>
          <input
            type="file"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            id="fileUpload"
          />
          <label
            htmlFor="fileUpload"
            className={style.editPromptTemplateCancelButton}
            style={{
              width: "60%",
              height: 37,
              color: state.store.config.colorPrimary,
              borderColor: state.store.config.colorPrimary,
            }}
          >
            Hochladen
          </label>
        </div>
        <div className={style.tasksLibBox}>
          <p style={{ fontSize: 15 }}>{secondWindowText}</p>
          <button
            disabled={data.length === 0 || inProgress}
            onClick={analyzeData}
            className={style.createNewTemplateButton}
            style={
              data.length === 0 || inProgress
                ? {
                    width: "60%",
                    color: state.store.config.colorPrimary,
                    borderColor: state.store.config.colorPrimary,
                  }
                : {
                    width: "60%",
                    color: "#FFFFFF",
                    backgroundColor: state.store.config.colorPrimary,
                  }
            }
          >
            {inProgress ? "In progress" : "Start"}
          </button>
        </div>
        {/* <select
          id="llm-select"
          value={selectedLLM}
          onChange={(e) => saveSelectedLLM(e)}
          className={style.llmSelect}
          style={{ width: "100%", margin: 0 }}
        >
          <option value={"llama"}>Ollama: Llama 3.1</option>
          <option value={"gpt-4o"}>OpenAI: gpt-4o</option>
          <option value={"gpt-4o-mini"}>OpenAI: gpt-4o-mini</option>
          <option value={"gpt-3.5-turbo"}>OpenAI: gpt-3.5-turbo</option>
        </select> */}
      </div>
    </>
  );
};

export default SurveyAnalysis;
