import { MemoryVectorStore } from "langchain/vectorstores/memory";
import axios from "axios";
import { Document } from "@langchain/core/documents";
import { HuggingFaceInferenceEmbeddings } from "@langchain/community/embeddings/hf";
import {
  // llmLlama,
  llmOpenAI,
  llmOpenAImini,
  llmOpenAIThreeFiveTurbo,
} from "./chains";
import { answer_prompt_crawler } from "./template";
import { StringOutputParser } from "@langchain/core/output_parsers";
import {
  DataScrapingRequestType,
  MemoryVectorStoreAddMessageRequestType,
} from "../types/types";

const serverURL = process.env.REACT_APP_SERVER_URL;
const huggingFaceApiKey = process.env.REACT_APP_HUGGING_FACE_API_KEY;

const embeddings = new HuggingFaceInferenceEmbeddings({
  apiKey: huggingFaceApiKey,
  model: "embaas/sentence-transformers-multilingual-e5-base",
});
const vectorStore = new MemoryVectorStore(embeddings);

const downloadDocsFromWebsite = async (
  link: string,
  siteId: string | undefined
) => {
  let docs;
  try {
    const res: DataScrapingRequestType = await axios.post(
      `${serverURL}/${siteId}/data-scraping`,
      {
        link: link,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      }
    );

    docs = res.data.result;

    if (!docs || docs.length === 0) {
      throw new Error("No documents retrieved from the server.");
    }

    docs = docs.map((doc: Document) => {
      if (!doc.pageContent) {
        throw new Error("Document missing `page_content`.");
      }
      return {
        pageContent: doc.pageContent,
        metadata: doc.metadata || {},
      };
    });
  } catch (error) {
    console.error("Error downloading or processing documents: ", error);
    return;
  }

  try {
    await vectorStore.addDocuments(docs);
    console.log("Documents added to vector store successfully.");
  } catch (error) {
    console.error("Error adding documents to vector store: ", error);
  }
};

const addMessageToDb = async (
  question: string,
  currentChatId: string,
  humanMessageId: string,
  siteId: string | undefined
) => {
  const payload = {
    question: question,
    chat_id: currentChatId,
    human_message_id: humanMessageId,
  };
  try {
    const res: MemoryVectorStoreAddMessageRequestType = await axios.post(
      `${serverURL}/${siteId}/memory-vector-store-add-message`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      }
    );
    console.log("Add message to db memory vs " + res.data.done);
  } catch (error) {
    console.error(error);
  }
};

const memoryVectorStoreRetriever = async (question: string) => {
  const mmrRetriever = vectorStore.asRetriever({
    searchType: "mmr",
    searchKwargs: {
      fetchK: 10,
    },
    k: 2,
  });

  const res = await mmrRetriever.invoke(question);
  return res;
};

// const answer_chain_crawler_llama = answer_prompt_crawler
//   .pipe(llmLlama)
//   .pipe(new StringOutputParser());
const answer_chain_crawler_openai = answer_prompt_crawler
  .pipe(llmOpenAI)
  .pipe(new StringOutputParser());
const answer_chain_crawler_openai_mini = answer_prompt_crawler
  .pipe(llmOpenAImini)
  .pipe(new StringOutputParser());
const answer_chain_openai_crawler_three_five_turbo = answer_prompt_crawler
  .pipe(llmOpenAIThreeFiveTurbo)
  .pipe(new StringOutputParser());

export {
  downloadDocsFromWebsite,
  memoryVectorStoreRetriever,
  addMessageToDb,
  // answer_chain_crawler_llama,
  answer_chain_crawler_openai,
  answer_chain_crawler_openai_mini,
  answer_chain_openai_crawler_three_five_turbo,
};
