import React, { useContext, useEffect, useState } from "react";
import style from "./NewUI.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";
import DataUploadElements from "../common/DataUploadElements";
import {
  AccessLevelType,
  GetAccessLevelsRequestType,
  GetSourcesRequestType,
  RAGDocumentType,
} from "../../types/types";

const DataUpload = () => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const state = useContext(StoreContext);

  const [accessLevels, setAccessLevels] = useState<AccessLevelType[]>([]);
  const [selectedSidebarItem, setSelectedSidebarItem] =
    useState<string>("Dokumentenliste");
  let sidebarItems = [
    "Dokumentenliste",
    "Dokumente hochladen",
    "Rechts dokumente hochladen",
  ];

  if (siteId && siteId === "bvmw") {
    sidebarItems = ["Dokumentenliste", "Dokumente hochladen"];
  }

  const [sources, setSources] = useState<RAGDocumentType[]>([]);

  const getAccessLevels = async () => {
    try {
      const res: GetAccessLevelsRequestType = await axios.get(
        `${serverURL}/${siteId}/get-access-levels`
      );
      setAccessLevels(res.data.result);
    } catch (error) {
      console.log("Error while getAccessLevelsNames " + error);
    }
  };

  const getSources = async () => {
    try {
      const response: GetSourcesRequestType = await axios.get(
        `${serverURL}/${siteId}/sources`
      );

      setSources(response.data);
    } catch (error) {
      console.error("There was an error fetching the sources!", error);
    }
  };

  const refreshData = async () => {
    await getSources();
    await getAccessLevels();
  };
  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.sidebarWrapper}>
        {sidebarItems.map((itemName: string, index: number) => (
          <span
            key={index}
            className={
              selectedSidebarItem === itemName
                ? style.sidebarItemSelected
                : style.sidebarItem
            }
            style={
              selectedSidebarItem === itemName
                ? {
                    color: state.store.config.colorPrimary,
                    borderRightColor: state.store.config.colorPrimary,
                  }
                : {}
            }
            onClick={() => setSelectedSidebarItem(itemName)}
          >
            {itemName}
          </span>
        ))}
      </div>
      {siteId && (
        <DataUploadElements
          refreshData={refreshData}
          selectedSidebarItem={selectedSidebarItem}
          accessLevels={accessLevels}
          sources={sources}
          siteId={siteId}
        />
      )}
    </div>
  );
};

export default DataUpload;
