import React, { useContext, useState } from "react";
import style from "./NewUI.module.css";
import axios from "axios";
import oldStyle from "../AdminPage/AdminPage.module.css";
import DocumentItems from "../AdminPage/DocumentItems";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";
import {
  AccessLevelType,
  CreateNewAccessLevelRequestType,
  DeleteAccessLevelRequestType,
  DeleteFileRequestType,
  RAGDocumentType,
} from "../../types/types";

type DocumentsListType = {
  refreshData: () => void;
  accessLevels: AccessLevelType[];
  sources: RAGDocumentType[];
};
const DocumentsList: React.FC<DocumentsListType> = ({
  refreshData,
  accessLevels,
  sources,
}) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const state = useContext(StoreContext);

  const { siteId } = useParams();
  const [newAccessGroupName, setNewAccessGroupName] = useState<string>("");
  const [createAccessLevelGroupMode, setCreateAccessLevelGroupMode] =
    useState<boolean>(false);
  const [selectedAccessLevel, setSelectedAccessLevel] =
    useState<AccessLevelType>({ access_level: 0, access_level_name: "Alles" });

  const deleteFileFromDatabase = async (sourceValue: string) => {
    try {
      const res: DeleteFileRequestType = await axios.post(
        `${serverURL}/${siteId}/delete-file`,
        {
          source: sourceValue,
        }
      );
      console.log("Deleting file from database " + res.data);
    } catch (error) {
      console.log("Error during deleting file from database");
    }

    refreshData();
  };

  const getNextAccessLevel = (documents: RAGDocumentType[]): number => {
    const highestAccessLevel = documents.reduce((max, doc) => {
      return doc.access_level > max ? doc.access_level : max;
    }, 0);
    return highestAccessLevel + 1;
  };

  const createNewAccessLevelGroup = async () => {
    const newValue = getNextAccessLevel(sources);
    try {
      const res: CreateNewAccessLevelRequestType = await axios.post(
        `${serverURL}/${siteId}/create-new-access-level`,
        {
          access_level_name: newAccessGroupName,
          access_level_value: newValue,
        }
      );
      console.log(res.data.result);
      setCreateAccessLevelGroupMode(!createAccessLevelGroupMode);
      await refreshData();
    } catch (error) {
      console.error("Create new access level group error - " + error);
    }
  };

  const deleteAccessLevelGroup = async (accessLevelName: string) => {
    try {
      const res: DeleteAccessLevelRequestType = await axios.post(
        `${serverURL}/${siteId}/delete-access-level`,
        { access_level_name: accessLevelName }
      );
      console.log(res.data.result);
      await refreshData();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
      }}
    >
      <div className={style.sidebarWrapper} style={{ gap: 10 }}>
        {!createAccessLevelGroupMode ? (
          <button
            className={style.createNewAccessLevelButton}
            onClick={() =>
              setCreateAccessLevelGroupMode(!createAccessLevelGroupMode)
            }
            style={{
              color: state.store.config.colorPrimary,
              borderColor: state.store.config.colorPrimary,
            }}
          >
            Neuen Ordner anlegen
          </button>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              style={{ fontSize: 12, borderRadius: 0 }}
              value={newAccessGroupName}
              onChange={(e) => setNewAccessGroupName(e.currentTarget.value)}
              className={oldStyle.createAccessLevelGroupInput}
              placeholder="Enter new name"
            />
            <button
              disabled={!newAccessGroupName}
              style={{ fontSize: 12, borderRadius: 0 }}
              onClick={() => createNewAccessLevelGroup()}
            >
              Submit
            </button>
          </div>
        )}
        <span
          onClick={() =>
            setSelectedAccessLevel({
              access_level: 0,
              access_level_name: "Alles",
            })
          }
          className={
            selectedAccessLevel.access_level_name === "Alles"
              ? style.sidebarItemSelected
              : style.sidebarItem
          }
          style={
            selectedAccessLevel.access_level_name === "Alles"
              ? {
                  color: state.store.config.colorPrimary,
                  borderRightColor: state.store.config.colorPrimary,
                }
              : {}
          }
        >
          Alles
        </span>
        {accessLevels.length !== 0 &&
          accessLevels.map((accessLevel: AccessLevelType, index: number) => (
            <span
              key={index}
              onClick={() => setSelectedAccessLevel(accessLevel)}
              className={
                selectedAccessLevel.access_level_name ===
                accessLevel.access_level_name
                  ? style.sidebarItemSelected
                  : style.sidebarItem
              }
              style={
                selectedAccessLevel.access_level_name ===
                accessLevel.access_level_name
                  ? {
                      color: state.store.config.colorPrimary,
                      borderRightColor: state.store.config.colorPrimary,
                    }
                  : {}
              }
            >
              {accessLevel.access_level_name}
            </span>
          ))}
      </div>

      <div className={style.chatWrapper}>
        <div
          className={style.chatMessagesWrapper}
          style={{ height: "100%", maxHeight: "100%" }}
        >
          {accessLevels.length !== 0 ? (
            selectedAccessLevel.access_level_name === "Alles" ? (
              accessLevels.map(
                (accessLevelItem: AccessLevelType, index: number) => (
                  <div key={index} className={oldStyle.documentItemParent}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3
                        style={{ color: state.store.config.colorPrimary }}
                        key={index}
                      >
                        {accessLevelItem.access_level_name}
                      </h3>
                      {!sources.some(
                        (doc) =>
                          doc.access_level === accessLevelItem.access_level
                      ) &&
                        state.store.group_role === "CEO" && (
                          <button
                            onClick={() =>
                              deleteAccessLevelGroup(
                                accessLevelItem.access_level_name
                              )
                            }
                            style={{
                              color: "red",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            X
                          </button>
                        )}
                    </div>
                    <div className={oldStyle.sourcesWrapper}>
                      <DocumentItems
                        sources={sources.filter(
                          (doc: RAGDocumentType) =>
                            doc.access_level === accessLevelItem.access_level
                        )}
                        deleteFileFromDatabase={deleteFileFromDatabase}
                        accessLevels={accessLevels}
                        refreshData={refreshData}
                        type="admin"
                      />
                    </div>
                  </div>
                )
              )
            ) : (
              <div className={oldStyle.documentItemParent}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ color: state.store.config.colorPrimary }}>
                    {selectedAccessLevel.access_level_name}
                  </h3>
                  {!sources.some(
                    (doc) =>
                      doc.access_level === selectedAccessLevel.access_level
                  ) &&
                    state.store.group_role === "CEO" && (
                      <button
                        onClick={() =>
                          deleteAccessLevelGroup(
                            selectedAccessLevel.access_level_name
                          )
                        }
                        style={{
                          color: "red",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        X
                      </button>
                    )}
                </div>
                <div className={oldStyle.sourcesWrapper}>
                  <DocumentItems
                    sources={sources.filter(
                      (doc: RAGDocumentType) =>
                        doc.access_level === selectedAccessLevel.access_level
                    )}
                    deleteFileFromDatabase={deleteFileFromDatabase}
                    accessLevels={accessLevels}
                    refreshData={refreshData}
                    type="admin"
                  />
                </div>
              </div>
            )
          ) : (
            "No access levels, please create one"
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsList;
