import React, { useEffect, useState } from "react";
import style from "./AdminPage.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  CreateNewGroupRequestType,
  GetAllGroupsRequestType,
  GroupType,
} from "../../types/types";

type GroupCreationPropsType = {
  navigate: (path: string, data: { state: any }) => void;
};

const GroupCreationTagsAssigning: React.FC<GroupCreationPropsType> = ({
  navigate,
}) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();

  const [createNewGroupMode, setCreateNewGroupMode] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [groups, setGroups] = useState<GroupType[]>([]);

  const createNewGroup = async () => {
    try {
      const res: CreateNewGroupRequestType = await axios.post(
        `${serverURL}/${siteId}/create-new-group`,
        {
          new_group_name: newGroupName,
        }
      );
      console.log(res.data.result);
      await getAllGroups();
    } catch (error) {
      console.error("Error during create new group - " + error);
    }

    setCreateNewGroupMode(!createNewGroupMode);
  };

  const getAllGroups = async () => {
    try {
      const res: GetAllGroupsRequestType = await axios.get(
        `${serverURL}/${siteId}/get-all-groups`
      );
      console.log(res.data.groups_list);
      setGroups(res.data.groups_list);
    } catch (error) {
      console.error("Get all groups error - " + error);
    }
  };

  useEffect(() => {
    getAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.dataUploadContainer}>
      <h1>Groups Management</h1>
      {!createNewGroupMode ? (
        <button
          className={style.createAccessLevelGroupButton}
          onClick={() => setCreateNewGroupMode(!createNewGroupMode)}
        >
          Create new group
        </button>
      ) : (
        <div className={style.createAccessLevelGroupInputBtnWrapper}>
          <input
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.currentTarget.value)}
            className={style.createAccessLevelGroupInput}
            placeholder="Enter new name for group"
          />
          <button
            disabled={!newGroupName}
            style={{ fontSize: 16 }}
            onClick={() => createNewGroup()}
          >
            Submit
          </button>
        </div>
      )}

      <h2>All groups</h2>

      {groups ? (
        groups.map((groupItem: GroupType, index: number) => (
          <div
            className={style.uploadedFileWrapper}
            key={index}
            onClick={() => navigate("group-item", { state: groupItem._id })}
          >
            <p style={{ display: "flex", fontSize: 22, gap: 5 }}>
              <img style={{ height: 30 }} src="/images/users.svg" alt="users" />
              {groupItem.group_name}
            </p>
            <button className={style.sourceDeleteButton}>
              <img
                style={{ height: 30 }}
                src="/images/clipboard.svg"
                alt="edit"
              />
            </button>
          </div>
        ))
      ) : (
        <div>Group list is empty</div>
      )}
    </div>
  );
};

export default GroupCreationTagsAssigning;
