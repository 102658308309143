/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import style from "./NewUI.module.css";
import axios from "axios";
import { StoreContext } from "../../data/Context";
import { useNavigate, useParams } from "react-router-dom";
import {
  // answer_chain_llama,
  answer_chain_openai,
  // answer_chain_openai_mini,
  // answer_chain_openai_three_five_turbo,
  getContext,
  sendAnswerToDb,
  // standalone_question_chain_llama,
  standalone_question_chain_openai,
  // standalone_question_chain_openai_mini,
  // standalone_question_chain_openai_three_five_turbo,
} from "../../langchain/chains";
import Sidebar from "../Sidebar/Sidebar";
import { v4 } from "uuid";
import AiMessage from "../common/AiMessage";
import {
  ChatType,
  CreateNewChatRequestType,
  FetchAllChatsRequestType,
  FetchUserDataRequestType,
  // GetIntentNamesFromIntentTemplates,
  GetPromptTemplateContentByIdRequestType,
  GetUserIntentTemplateByTemplateName,
} from "../../types/types";
// import { analyseUserIntentsChain } from "../../langchain/UserIntents";

// export type SelectedLLMType = "llama" | "gpt-4o" | "gpt-4o-mini" | "gpt-3.5-turbo";

const ChatAndSidebar = () => {
  const initialMessage: ChatType = {
    chat_id: "123",
    chat_name: "Chat Room",
    user_id: "user1",
    chat_content: [],
    last_changed: "",
    role: "user",
  };
  const { siteId } = useParams();

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const state = useContext(StoreContext);
  const [userInput, setUserInput] = useState("");
  const [chatsArray, setChatsArray] = useState<ChatType[]>([]);
  const [currentChat, setCurrentChat] = useState(initialMessage);
  const [accessLevels, setAccessLevels] = useState<string[]>([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>("");
  // const [selectedLLM, setSelectedLLM] = useState<string>("openai");
  const [generatingInProgress, setGeneratingInProgress] =
    useState<boolean>(false);

  // const saveSelectedLLM = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   localStorage.setItem("lastUsedLLM", e.currentTarget.value);
  //   setSelectedLLM(e.currentTarget.value);
  // };

  // const getSavedSelectedLLM = async () => {
  //   const savedLLM = localStorage.getItem("lastUsedLLM");
  //   savedLLM && setSelectedLLM(savedLLM);
  // };

  const fetchUserData = async (): Promise<any> => {
    try {
      const response: FetchUserDataRequestType = await axios.post(
        `${serverURL}/${siteId}/get-user-data`,
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const userData = response.data.user_data;
      if (userData) {
        state.setStore((prevState) => ({
          ...prevState,
          userId: userData.id,
          email: userData.email,
          username: userData.username,
          chatList: userData.chats_id,
          chatInUse: userData.lastUsedChat,
          group_id: userData.group_id,
          group_role: userData.group_role,
          template_in_use: userData.template_in_use,
        }));
      }
      return response.data.user_data;
    } catch (error) {
      console.log(error);
      navigate(`/${siteId}/`);
    }
  };

  const selectChat = (chat_id: string) => {
    const selectedChat = chatsArray.find((chat) => chat.chat_id === chat_id);
    selectedChat
      ? setCurrentChat(selectedChat)
      : console.log("SELECT CHAT ERROR");
  };

  const fetchAllChats = async () => {
    try {
      const response: FetchAllChatsRequestType = await axios.post(
        `${serverURL}/${siteId}/get-all-chats`,
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );

      const chatsArr = response.data.chats_array;

      if (chatsArr.length > 0) {
        setChatsArray(chatsArr);
        setCurrentChat(chatsArr[0]);
      } else {
        createChat();
      }
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  useEffect(() => {
    // getSavedSelectedLLM();
    if (state.store.userId === 0) {
      fetchUserData();
    }

    if (state.store.userId !== 0) {
      fetchAllChats();
      //   setIsLoading(false);
    }
  }, [state.store.userId]);

  useEffect(() => {
    if (state.store.group_id !== "") {
      getAccessLevels();
    }
  }, [state.store.group_role]);

  const getPromptTemplateContent = async () => {
    try {
      if (state.store.template_in_use !== "") {
        const res: GetPromptTemplateContentByIdRequestType = await axios.post(
          `${serverURL}/${siteId}/get-prompt-template-content-by-id`,
          { prompt_template_id: state.store.template_in_use }
        );
        return res.data.result;
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const getAnswer = async (
    question: string,
    convHistory: any,
    chatId: string,
    humanMessageId: string
  ) => {
    try {
      let selectedAccessLevels: string[] = [];
      if (selectedAccessLevel === "Alles") {
        accessLevels.forEach((accessLevel: string) => {
          selectedAccessLevels.push(accessLevel);
        });
      } else {
        selectedAccessLevels = [selectedAccessLevel];
      }
      const context = await getContext(
        question,
        chatId,
        selectedAccessLevels,
        siteId,
        humanMessageId
      );
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // USER INTENT TEMPLATES LOGIC START
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // const intentsNamesRequestResult: GetIntentNamesFromIntentTemplates =
      //   await axios.get(
      //     `${serverURL}/${siteId}/get-intent-names-from-intent-templates`
      //   );

      // const intentsNames =
      //   intentsNamesRequestResult.data.result;

      let currentIntent: string = "None";
      // if (intentsNames) {
      //   const currentIntentRequestRes: { intentName: string } =
      //     await analyseUserIntentsChain.invoke({
      //       intents: JSON.stringify(intentsNames),
      //       question: question,
      //     });
      //   currentIntent = currentIntentRequestRes.intentName;
      // }

      let intentTemplate: string = "";

      if (currentIntent !== "None") {
        const intentTemplateRequestRes: GetUserIntentTemplateByTemplateName =
          await axios.post(
            `${serverURL}/${siteId}/get-user-intent-template-by-template-name`,
            {
              intent_name: currentIntent,
            }
          );

        intentTemplate = intentTemplateRequestRes.data.result;
      }

      setCurrentChat((prevCurrentChat) => {
        const updatedChatContent = [...prevCurrentChat.chat_content];
        const lastIndex = updatedChatContent.length - 1;

        updatedChatContent[lastIndex] = {
          ...updatedChatContent[lastIndex],
          intent: currentIntent,
        };

        return { ...prevCurrentChat, chat_content: updatedChatContent };
      });
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      // USER INTENT TEMPLATES LOGIC END
      //---------------------------------------------------------------------------------------------------------------------------------------------------
      let selectedLLMAnswerChain = answer_chain_openai;
      let selectedLLMStandaloneQuestionChain = standalone_question_chain_openai;

      const standaloneQuestion: { standaloneQuestion: string } =
        await selectedLLMStandaloneQuestionChain.invoke({
          conv_history: convHistory,
          question: question,
        });

      console.log(standaloneQuestion.standaloneQuestion);

      const promptTemplateContent = await getPromptTemplateContent();

      let serverUrlForChain: string;
      let siteIdForChain: string;

      serverURL !== undefined
        ? (serverUrlForChain = serverURL)
        : (serverUrlForChain = "");

      siteId !== undefined ? (siteIdForChain = siteId) : (siteIdForChain = "");
      const response = await selectedLLMAnswerChain.stream({
        question: standaloneQuestion.standaloneQuestion,
        context: JSON.stringify(context),
        conv_history: JSON.stringify(convHistory),
        promptTemplateContent: promptTemplateContent
          ? promptTemplateContent
          : "",
        serverURL: serverUrlForChain,
        siteId: siteIdForChain,
        answer_structure: intentTemplate,
      });
      const chunks = [];

      for await (const chunk of response) {
        chunks.push(chunk);
        setCurrentChat((prevCurrentChat) => {
          const updatedChatContent = [...prevCurrentChat.chat_content];
          const lastIndex = updatedChatContent.length - 1;

          updatedChatContent[lastIndex] = {
            ...updatedChatContent[lastIndex],
            text: (updatedChatContent[lastIndex].text || "") + chunk,
          };

          return { ...prevCurrentChat, chat_content: updatedChatContent };
        });
      }

      return { chunks, currentIntent };
    } catch (error) {
      console.log("getAnswerChain error - " + error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // GET ANSWER WRAPPER FUNCTION WHICH SENDS FULL ANSWER TO DATABASE GET ANSWER WRAPPER FUNCTION WHICH SENDS FULL ANSWER TO DATABASE
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const fetchAnswer = async (
    question: string,
    chatContent: { text?: any; sender?: string | undefined }[],
    chatId: string,
    aiMessageId: string,
    humanMessageId: string
  ) => {
    try {
      const answer = await getAnswer(
        question,
        chatContent,
        chatId,
        humanMessageId
      );
      const combinedChunks = answer?.chunks?.join("");

      await sendAnswerToDb(
        combinedChunks,
        chatId,
        siteId,
        aiMessageId,
        answer?.currentIntent
      );
    } catch (error) {
      console.error("Error fetching answer:", error);
      throw error;
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const updateChatName = async (chatName: string) => {
    const payload = {
      chat_id: currentChat.chat_id,
      chat_name: chatName,
    };
    try {
      await axios.post(`${serverURL}/${siteId}/update-chat-name`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      });
    } catch (error) {
      console.error("ERROR UPDATE CHAT NAME - " + error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const createChat = async () => {
    await deleteEmptyChats();
    let chatName = userInput;
    if (userInput === "") {
      chatName = "New Chat";
    }
    const payload = {
      question: chatName,
    };
    try {
      // setIsLoading(true)
      const result: CreateNewChatRequestType = await axios.post(
        `${serverURL}/${siteId}/create-new-chat`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const newChatObj = result.data.new_chat_obj;
      setCurrentChat(newChatObj);
      fetchUserData();
      fetchAllChats();
      // setIsLoading(false)
      return newChatObj.chat_id;
    } catch (error) {
      console.log("Create Chat error - " + error);
    }
  };

  const deleteEmptyChats = async () => {
    try {
      await axios.get(`${serverURL}/${siteId}/delete-empty-chats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      });
    } catch (error) {}
  };

  const getAccessLevels = async () => {
    try {
      const res = await axios.post(
        `${serverURL}/${siteId}/get-user-access-levels`,
        {
          group_id: state.store.group_id,
          user_group_role: state.store.group_role,
        }
      );
      setAccessLevels(res.data.access_levels);
      if (res.data.access_levels.length !== 0) {
        setSelectedAccessLevel("Alles");
      }
    } catch (error) {
      console.error("getAccessLevels error - " + error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const progressConversation = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    questionInput: string
  ) => {
    event.preventDefault();
    setGeneratingInProgress(true);
    try {
      if (currentChat.chat_name === "New Chat") {
        updateChatName(questionInput);
        setCurrentChat({ ...currentChat, chat_name: questionInput });
      }
      setUserInput("");

      state.setStore({
        ...state.store,
        chatInUse: currentChat.chat_id,
      });

      const aiMessageId = v4();
      const humanMessageId = v4();

      const newMessages = [
        ...currentChat.chat_content,
        {
          text: questionInput,
          sender: "human",
          chat_message_id: humanMessageId,
        },
        { text: "", sender: "ai", chat_message_id: aiMessageId },
      ];

      const updatedMessagesArray = {
        ...currentChat,
        chat_content: newMessages,
      };

      setCurrentChat(updatedMessagesArray);

      await fetchAnswer(
        questionInput,
        newMessages,
        currentChat.chat_id,
        aiMessageId,
        humanMessageId
      );
      await fetchUserData();
      setGeneratingInProgress(false);
    } catch (error) {
      console.log("Error in progressConversation:", error);
      setGeneratingInProgress(false);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT R
  //---------------------------------------------------------------------------------------------------------------------------------------------------

  const setRatingToMessage = async (
    rating: string,
    messageId: string | undefined
  ) => {
    const index = currentChat.chat_content.findIndex(
      (message) => message.chat_message_id === messageId
    );

    if (
      (currentChat.chat_content[index].rating === "like" &&
        rating === "like") ||
      (currentChat.chat_content[index].rating === "dislike" &&
        rating === "dislike")
    ) {
      return;
    }

    let intent: string | undefined = "";
    let question: string = "";
    let answer: string = "";
    if (index !== -1 && index > 0) {
      answer = currentChat.chat_content[index].text;
      question = currentChat.chat_content[index - 1].text;
      intent = currentChat.chat_content[index].intent;
    } else {
      console.error("Message not found or no previous question.");
    }
    try {
      setChatsArray((prevChatsArray) =>
        prevChatsArray.map((chat) =>
          chat.chat_id === currentChat.chat_id
            ? {
                ...chat,
                chat_content: chat.chat_content.map((message) =>
                  message.chat_message_id === messageId
                    ? { ...message, rating: rating }
                    : message
                ),
              }
            : chat
        )
      );

      setCurrentChat((prevCurrentChat) => {
        return {
          ...prevCurrentChat,
          chat_content: prevCurrentChat.chat_content.map((message) =>
            message.chat_message_id === messageId
              ? { ...message, rating: rating }
              : message
          ),
        };
      });

      await axios.post(
        `${serverURL}/${siteId}/set-message-rating`,
        {
          chat_id: currentChat.chat_id,
          rating: rating,
          message_id: messageId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );

      if (rating === "like") {
        await axios.post(
          `${serverURL}/${siteId}/user-intents-on-like-analyse`,
          {
            question: question,
            answer: answer,
            intent: intent,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + localStorage.getItem(`${siteId}_access_token`),
            },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setCommentToMessage = async (
    comment: string | undefined,
    messageId: string | undefined
  ) => {
    try {
      setChatsArray((prevChatsArray) =>
        prevChatsArray.map((chat) =>
          chat.chat_id === currentChat.chat_id
            ? {
                ...chat,
                chat_content: chat.chat_content.map((message) =>
                  message.chat_message_id === messageId
                    ? { ...message, comment: comment }
                    : message
                ),
              }
            : chat
        )
      );

      setCurrentChat((prevCurrentChat) => {
        return {
          ...prevCurrentChat,
          chat_content: prevCurrentChat.chat_content.map((message) =>
            message.chat_message_id === messageId
              ? { ...message, comment: comment }
              : message
          ),
        };
      });

      await axios.post(
        `${serverURL}/${siteId}/set-message-comment`,
        {
          chat_id: currentChat.chat_id,
          comment: comment,
          message_id: messageId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
    } catch (error) {}
  };

  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.sidebarWrapper} style={{ paddingLeft: 0 }}>
        <button className={style.newChatBtn} onClick={createChat}>
          New Chat
        </button>
        <Sidebar
          chatList={chatsArray && chatsArray}
          currentChatId={currentChat ? currentChat.chat_id : undefined}
          selectChat={selectChat}
        />
      </div>
      <div className={style.chatWrapper}>
        <div className={style.chatMessagesWrapper}>
          {currentChat.chat_content.map((message, index) =>
            message.sender === "ai" ? (
              <AiMessage
                key={index}
                message={message}
                index={index}
                currentChat={currentChat}
                setRatingToMessage={setRatingToMessage}
                setCommentToMessage={setCommentToMessage}
                generatingInProgress={generatingInProgress}
              />
            ) : (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-end" }}
              >
                <div key={index} className={`speech speech-human`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <form id="form" className={style.inputWrapper}>
          <input
            name="user-input"
            type="text"
            id="user-input"
            required
            value={userInput}
            onChange={(e) => setUserInput(e.currentTarget.value)}
            placeholder="Schreibe eine Nachricht"
            style={{
              borderBottomLeftRadius: 50,
              borderTopLeftRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          />
          <button
            id="submit-btn"
            disabled={!userInput.trim() || generatingInProgress}
            className="submit-btn"
            onClick={(e) => progressConversation(e, userInput)}
            style={{
              borderBottomRightRadius: 50,
              borderTopRightRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          >
            <img src="/images/send.svg" className="send-btn-icon" alt="" />
          </button>
        </form>
      </div>
      <div className={style.sidebarWrapperRight}>
        {/* <select
          id="llm-select"
          value={selectedLLM}
          onChange={(e) => saveSelectedLLM(e)}
          className={style.llmSelect}
        >
          <option value={"llama"}>Ollama: Llama 3.1</option>
          <option value={"gpt-4o"}>OpenAI: gpt-4o</option>
          <option value={"gpt-4o-mini"}>OpenAI: gpt-4o-mini</option>
          <option value={"gpt-3.5-turbo"}>OpenAI: gpt-3.5-turbo</option>
        </select> */}
        <span
          onClick={() => setSelectedAccessLevel("Alles")}
          className={
            selectedAccessLevel === "Alles"
              ? style.sidebarItemRightSelected
              : style.sidebarItemRight
          }
          style={
            selectedAccessLevel === "Alles"
              ? {
                  color: state.store.config.colorPrimary,
                  borderLeftColor: state.store.config.colorPrimary,
                }
              : {}
          }
        >
          Alles
        </span>
        {accessLevels.length !== 0 &&
          accessLevels.map((accessLevel: string, index: number) => (
            <span
              key={index}
              onClick={() => setSelectedAccessLevel(accessLevel)}
              className={
                selectedAccessLevel === accessLevel
                  ? style.sidebarItemRightSelected
                  : style.sidebarItemRight
              }
              style={
                selectedAccessLevel === accessLevel
                  ? {
                      color: state.store.config.colorPrimary,
                      borderLeftColor: state.store.config.colorPrimary,
                    }
                  : {}
              }
            >
              {accessLevel}
            </span>
          ))}
      </div>
    </div>
  );
};

export default ChatAndSidebar;
