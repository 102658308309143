import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

type StoreContextType = {
  store: StoreType;
  setStore: Dispatch<SetStateAction<StoreType>>;
};

type StoreType = {
  chatList: any[];
  userId: number | string;
  username: string;
  isAuthenticatedUser: boolean;
  email: string;
  chatInUse: string;
  role: string;
  group_id: string;
  group_role: string;
  template_in_use: string;
  config: {
    colorPrimary: string;
    logo: string;
  };
};

export const StoreContext = createContext<StoreContextType>({
  store: {
    chatList: [],
    userId: 0,
    username: "",
    isAuthenticatedUser: false,
    email: "",
    chatInUse: "",
    group_id: "",
    group_role: "",
    role: "",
    template_in_use: "",
    config: {
      colorPrimary: "",
      logo: "",
    },
  },
  setStore: () => {},
});

const ContextState = (props: { children: React.ReactNode }) => {
  const initialState = {
    chatList: [],
    userId: 0,
    username: "",
    isAuthenticatedUser: false,
    email: "",
    chatInUse: "",
    group_id: "",
    group_role: "",
    role: "",
    template_in_use: "",
    config: {
      colorPrimary: "",
      logo: "",
    },
  };

  const [store, setStore] = useState<StoreType>(initialState);

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {props.children}
    </StoreContext.Provider>
  );
};

export default ContextState;
