import {
  JsonOutputParser,
  StringOutputParser,
} from "@langchain/core/output_parsers";
import { llmOpenAI } from "./chains";
import { PromptTemplate } from "@langchain/core/prompts";

const askForRequiredFieldsInitialTemplate = `
        You are an expert bot who helps User to solve situation. 
        You will be provided with a object where keys represents information that User should provide us.
        Do not ask for any personal information.
        Your task is to ask User to provide information that need to solve or to help to solve User's situation.

        Provided keys: {requiredInformation}

        Format the answer with HTML elements.
        You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
        Do not add html block in answer.
        Write response in German language.
        `;

const getRequiredFieldsProgressiveTemplate = `
        You are an expert assistant helping a user solve their situation. 
        You will be provided with an object where the keys represent information that the user should provide, along with the user's answer.

        Your task is to:
        1. Assign information from the user's answer to the corresponding keys.
        2. If the user did not provide information for certain keys, leave those keys with the value 'undefined'.
        3. Only remove keys with the value 'undefined' if the user explicitly states that they have no more information to provide.

        Provided keys: {requiredInformation}
        User's answer: {question}

        Return an object with all keys from the provided object, assigning the user's responses where applicable.
        If the user did not mention certain information, keep those keys as 'undefined'. 
        Only remove keys that are 'undefined' if the user explicitly states they have no more information.

        Return the result as a JSON object with no preamble or explanation.
        `;

const askForRequiredFieldsTemplate = `
        You are an expert bot who helps User to solve situation. 
        You will be provided with a object where keys represents information that User should provide us.
        Do not ask for any personal information.
        As far as you are second part of the chain, your task is to ask User to provide additional information that is needed to solve or to help to solve User's situation.

        Provided keys: {requiredInformation}

        Format the answer with HTML elements.
        You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
        Do not add html block in answer.
        Write response in German language.
        `;

const checkIfEgoughInformationTemplate = `
        You are a legal analysis assistant.

        You have been provided with an object containing key-value pairs representing the information required for a specific case, along with a legal document or part of a legal document.

        **Task**:
        1. Compare the provided object with the information in the legal document.
        2. Determine if the information provided in the object is sufficient to fully address the situation described by the user.
        3. If the information is insufficient, identify any additional required fields based on the content of the legal document and the user’s case.

        **Instructions**:
        - Focus on the specific case details described by the user. Only include fields that directly relate to resolving or assessing the user’s situation.
        - Do **not** add general legal or document-related fields that do not apply to the user's specific case requirements.
        - Add only essential information that is explicitly required to fully evaluate the case, based on what’s mentioned in the provided legal document and what aligns with the user’s scenario.
        - Set 'enough' to true if the information in the provided object is fully sufficient for evaluating the case. If it is not sufficient, set 'enough' to false and add any essential missing fields to the object, setting their values to "undefined".

        Provided object: {requiredFields}.
        Legal document: {legalDocument}.

        Return JSON in the following format with no preamble or explanation:
        {{"enough": true/false, "requiredFields": {{... all previous keys and values, additional required fields}} }}
        `;

const combineObjectsTemplate = `
        You are an expert bot who can combine objects into 1 object.
        You will be provided with objects, your task is to analyse those objects and remove repeting keys or keys that have similar meanings.
        Return JSON with no preamble or explanation.

        Objects: {objects}
      `;

const askForFieldsAfterAnalyseTemplate = `
        You are an expert bot who helps User to solve situation. 
        You will be provided with a object where keys represents information that User should provide us.
        Your task is to explain user that system has found similar case to his own and we need some additional information.
        Do not ask for any personal information.
        Additional information you should ask is in Provided keys and has value - 'undefined'

        Provided keys: {requiredInformation}

        Format the answer with HTML elements.
        You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
        Do not add html block in answer.
        Write response in German language.
        `;

const analyseForFinalAnswerTemplate = `
You are a legal analysis assistant.

You will be provided with an object containing key-value pairs that represent all available information for a specific case. Additionally, you will receive a legal document or a section of a legal document that may contain relevant legal principles or guidelines for evaluating the case.

**Task**:
1. Use the information in the provided object and the legal document section to generate a prediction or likely outcome for the user's case.
2. Explain your reasoning for this prediction clearly and concisely, referencing the information from the legal document section and the data in the provided object.
3. If this document section alone does not contain enough information to give a complete prediction, make a partial prediction based on what's currently available and specify what additional legal details or context may be needed to make a final prediction.
4. Understand that this is one part of the legal document and additional information may come in future parts, so each response should stand alone while recognizing potential gaps if applicable.

**Instructions**:
- Analyze the provided information in the 'requiredFields' object and the legal document section, and then create a prediction based on what can be reasonably inferred.
- Structure your prediction to include:
  - The **predicted outcome**.
  - A **justification** for this prediction based on details in the legal document and provided case information.
  - If necessary, a **note on missing information** if this section of the document is insufficient for a complete prediction.
- Assume you may receive additional document sections in future queries, so avoid definitive conclusions if key details appear to be missing.

Provided case details: {requiredFields}.
Legal document section: {legalDocument}.
`;

const combineForFinalAnswerTemplate = `
You are a legal analysis assistant.

You will be provided with an array of results, each containing a partial prediction, justification, and any noted missing information from different sections of a legal document.
Your task is to synthesize these partial results into one final, cohesive prediction for the user's case.

**Task**:
1. Review each result in the provided array.
2. Combine the individual predictions into a single, comprehensive prediction for the user's case.
3. Integrate the justifications into one consolidated explanation, capturing the key points from each section and ensuring that your final justification is cohesive and logically consistent.
4. If any missing information is mentioned across the results, note this concisely in the final response.

**Instructions**:
- Ensure that the final prediction and justification are clear, unified, and based on all relevant information across the document sections.
- When noting missing information, summarize any legal details that were identified as necessary in previous parts but were not provided.
- Structure your answer as follows:
  - **Final Prediction**: Provide a clear summary of the likely outcome based on all sections.
  - **Justification**: Explain the reasoning for this prediction, synthesizing the information from each section.
  - **Remaining Information Needed**: Concisely state any additional legal information required, if applicable; if all necessary information was covered, simply state “All relevant information has been provided.”

**Example Format**:

- **Final Prediction**: [Combined prediction]
- **Justification**: [Combined reasoning and explanation]
- **Remaining Information Needed**: [Summary of any missing information or “All relevant information has been provided”]

---

Results from document sections: {resultsArray}

Respond with a final prediction in the format above with no additional preamble.
Format the answer with HTML elements.
You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
Do not add html block in answer.
Write response in German language.
`;

const conversationAfterWorkflowPredictionTemplate = `
You are a legal advisor AI assisting a user with their specific situation. You have access to the following information:

1. Conversation History: This is a record of the conversation between you and the user up to this point, which will give context to their situation and any additional questions they have asked. 
2. Collected Information (requiredFields): This is the specific information collected about the user's case or situation, detailing relevant facts, specifics, or contextual details provided by the user.

Your task:
- Review the conversation history to understand the user's situation and any guidance provided thus far.
- Use the collected information (requiredFields) to offer targeted advice or a recommended course of action based on your understanding of the user's needs and the legal context.
- If any essential information is still missing, ask clarifying questions to gather it.
- Provide guidance or recommended steps in clear, simple language.

Requirements:
- Begin with a concise summary of the user's situation as you understand it from the conversation history and collected information.
- Provide actionable advice or suggested steps that are likely to help address or resolve the user's case.
- If you identify any missing but crucial information, list specific questions to ask the user to obtain it, ensuring it's relevant to solving the situation.
- Base your response only on the information available; if certain legal assumptions are necessary, state them briefly.

Situation Summary:
Provide a brief summary of the user's situation as gathered from the conversation history and requiredFields.

Legal Advice:
Offer clear, actionable legal advice tailored to the user's situation.

Next Steps/Questions:
If additional information is required, list any clarifying questions or next steps for the user.
  
**Data Provided**:
- Conversation History: {conversationHistory}
- Collected Information: {requiredFields}
- User's Question: {question}
---

Format the answer with HTML elements.
You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
Do not add html block in answer.
Write response in German language.
`;

const analyse_and_create_required_fields_legal_docs_template = `
You are an intelligent legal assistant designed to extract relevant information based on a user's specific situation. A user has provided the following situation:

"{question}"

Your task is to analyze the situation and identify the key pieces of information that would be relevant for evaluating this case. 
Generate fields that could be useful for the user to answer. 
Each field should be easy to answer and should directly relate to the situation described.

Based on the user's situation, generate an object with relevant keys. Set each key to 'undefined' until the user provides more details. 

Return a JSON object containing the generated keys, ensuring that each value is either a string or 'undefined'. 
Do not include any unnecessary fields or preamble in your response.
`;

const legal_standalone_question_template = `
You are a legal advisor AI assisting a user with their specific situation.
You will receive user's question/problem and some additional information.
Your task is to create standalone question, that will be used for information searching.

User's question: {question}.
Additional information: {information}.

Return your answer as JSON with key question with no preamble or explanation.
`;

const askForRequiredFieldsInitialPrompt = PromptTemplate.fromTemplate(
  askForRequiredFieldsInitialTemplate
);
const getRequiredFieldsProgressivePrompt = PromptTemplate.fromTemplate(
  getRequiredFieldsProgressiveTemplate
);
const checkIfEgoughInformationPrompt = PromptTemplate.fromTemplate(
  checkIfEgoughInformationTemplate
);
const askForRequiredFieldsPrompt = PromptTemplate.fromTemplate(
  askForRequiredFieldsTemplate
);
const combineObjectsPrompt = PromptTemplate.fromTemplate(
  combineObjectsTemplate
);
const askForFieldsAfterAnalysePrompt = PromptTemplate.fromTemplate(
  askForFieldsAfterAnalyseTemplate
);
const analyseForFinalAnswerPrompt = PromptTemplate.fromTemplate(
  analyseForFinalAnswerTemplate
);
const combineForFinalAnswerPrompt = PromptTemplate.fromTemplate(
  combineForFinalAnswerTemplate
);
const conversationAfterWorkflowPredictionPrompt = PromptTemplate.fromTemplate(
  conversationAfterWorkflowPredictionTemplate
);
const analyse_and_create_required_fields_legal_docs_prompt =
  PromptTemplate.fromTemplate(
    analyse_and_create_required_fields_legal_docs_template
  );
const legal_standalone_question_prompt = PromptTemplate.fromTemplate(
  legal_standalone_question_template
);

const askForRequiredFieldsInitialChain = askForRequiredFieldsInitialPrompt
  .pipe(llmOpenAI)
  .pipe(new StringOutputParser());
const getRequiredFieldsProgressiveChain = getRequiredFieldsProgressivePrompt
  .pipe(llmOpenAI)
  .pipe(new JsonOutputParser());
const askForRequiredFieldsChain = askForRequiredFieldsPrompt
  .pipe(llmOpenAI)
  .pipe(new StringOutputParser());
const checkIfEgoughInformationChain = checkIfEgoughInformationPrompt
  .pipe(llmOpenAI)
  .pipe(new JsonOutputParser());
const combineObjectsChain = combineObjectsPrompt
  .pipe(llmOpenAI)
  .pipe(new JsonOutputParser());
const askForFieldsAfterAnalyseChain = askForFieldsAfterAnalysePrompt
  .pipe(llmOpenAI)
  .pipe(new StringOutputParser());
const analyseForFinalAnswerChain = analyseForFinalAnswerPrompt
  .pipe(llmOpenAI)
  .pipe(new StringOutputParser());
const combineForFinalAnswerChain = combineForFinalAnswerPrompt
  .pipe(llmOpenAI)
  .pipe(new StringOutputParser());
const conversationAfterWorkflowPredictionChain =
  conversationAfterWorkflowPredictionPrompt
    .pipe(llmOpenAI)
    .pipe(new StringOutputParser());
const analyse_and_create_required_fields_legal_docs_chain =
  analyse_and_create_required_fields_legal_docs_prompt
    .pipe(llmOpenAI)
    .pipe(new JsonOutputParser());
const legal_standalone_question_chain = legal_standalone_question_prompt
  .pipe(llmOpenAI)
  .pipe(new JsonOutputParser());

export {
  combineObjectsChain,
  checkIfEgoughInformationChain,
  askForRequiredFieldsChain,
  getRequiredFieldsProgressiveChain,
  askForRequiredFieldsInitialChain,
  askForFieldsAfterAnalyseChain,
  analyseForFinalAnswerChain,
  combineForFinalAnswerChain,
  conversationAfterWorkflowPredictionChain,
  analyse_and_create_required_fields_legal_docs_chain,
  legal_standalone_question_chain,
};
