import React, { useContext, useEffect, useState } from "react";
import Login from "./Login";
import style from "./Login.module.css";
import Registration from "./Registration";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../../scss/main.scss";
import { StoreContext } from "../../data/Context";
import { ValidateTokenRequestType } from "../../types/types";

const Page = () => {
  const [componentToShow, setComponentToShow] = useState(0);
  const navigate = useNavigate();
  const { siteId } = useParams();
  const state = useContext(StoreContext);

  const changeComponent = (compNumber: number) => {
    setComponentToShow(compNumber);
  };
  const navigation = (path: string) => {
    navigate(path);
  };

  const serverURL = process.env.REACT_APP_SERVER_URL;

  const getSiteConfig = async () => {
    try {
      const res = await axios.get(`${serverURL}/${siteId}/get-site-config`);
      if (res && res.data && res.data.result) {
        const { color_primary, logo } = res.data.result;

        state.setStore((prevState) => {
          const newState = {
            ...prevState,
            config: {
              ...prevState.config,
              colorPrimary:
                color_primary || prevState.config.colorPrimary || "",
              logo: logo || prevState.config.logo || "",
            },
          };
          console.log("New state:", newState);
          return newState;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateToken = async () => {
    const token = localStorage.getItem(`${siteId}_access_token`);
    if (!token) {
      console.log("No token found");
      return;
    }
    try {
      const response: ValidateTokenRequestType = await axios.get(
        `${serverURL}/${siteId}/validate-token`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data.valid) {
        navigate(`/${siteId}/home`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    validateToken();
    getSiteConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.main}>
      <div className={style.container}>
        <div className={style.section} style={{ width: "50%" }}>
          <img src={`/images/${state.store.config.logo}`} alt="" />
        </div>
        <div className={style.section}>
          {componentToShow === 0 ? (
            <Login changeComponent={changeComponent} navigation={navigation} />
          ) : componentToShow === 1 ? (
            <Registration
              changeComponent={changeComponent}
              navigation={navigation}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page;
