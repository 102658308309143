import React, { useContext, useEffect, useState } from "react";
import style from "./NewUI.module.css";
import axios from "axios";
import { StoreContext } from "../../data/Context";
import { useParams } from "react-router-dom";
import {
  GetPromptTemplatesRequestType,
  PromptTemplateType,
} from "../../types/types";

type PromptTemplatePageType = {
  refresh: () => void;
};

const PromptTemplates: React.FC<PromptTemplatePageType> = ({ refresh }) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const state = useContext(StoreContext);
  const [templateName, setTemplateName] = useState<string | undefined>("");
  const [template, setTemplate] = useState<string | undefined>("");
  const [promptTemplates, setPromptTemplates] = useState<PromptTemplateType[]>(
    []
  );
  const [selectedSidebarItem, setSelectedSidebarItem] = useState<string>("1");
  const [editMode, setEditMode] = useState<boolean>(false);

  const createNewPromptTemplate = async () => {
    await axios.post(
      `${serverURL}/${siteId}/create-new-prompt-template`,
      {
        template_string: template,
        template_name: templateName,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      }
    );
    setTemplate("");
    setTemplateName("");
    await getPromptTemplates();
  };

  const getPromptTemplates = async () => {
    try {
      const res: GetPromptTemplatesRequestType = await axios.get(
        `${serverURL}/${siteId}/get-prompt-templates`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const templates = res.data.result;
      setPromptTemplates(templates);
      if (templates.length === 0) {
        setSelectedSidebarItem("create");
      } else {
        if (state.store.template_in_use !== "") {
          setSelectedSidebarItem(
            templates.find(
              (item: PromptTemplateType) =>
                item.id === state.store.template_in_use
            )?.template_name || res.data.result[0].template_name
          );
        } else {
          setSelectedSidebarItem(res.data.result[0].template_name);
        }
      }
    } catch (error) {
      console.error("Get prompt template - " + error);
    }
  };

  useEffect(() => {
    getPromptTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.store.template_in_use]);

  const openCreateNewPromptTemplate = () => {
    setTemplate("");
    setTemplateName("");
    setSelectedSidebarItem("create");
  };

  const switchChangePromptTemplate = () => {
    const item = promptTemplates.find(
      (item: PromptTemplateType) => item.template_name === selectedSidebarItem
    );
    setTemplate(item?.template_string);
    setTemplateName(item?.template_name);
    setEditMode(!editMode);
  };

  const activatePromptTemplate = async () => {
    try {
      await axios.post(
        `${serverURL}/${siteId}/activate-prompt-template`,
        {
          template_id: promptTemplates.find(
            (item: PromptTemplateType) =>
              item.template_name === selectedSidebarItem
          )?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      refresh();
    } catch (error) {
      console.error("Error activate prompt template - " + error);
    }
  };

  const deactivatePromptTemplate = async () => {
    try {
      await axios.get(`${serverURL}/${siteId}/deactivate-prompt-template`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      });
      refresh();
    } catch (error) {
      console.error("Error deactivate prompt template - " + error);
    }
  };
  const changePromptTemplate = async () => {
    try {
      await axios.post(
        `${serverURL}/${siteId}/change-prompt-template`,
        {
          template_string: template,
          template_name: templateName,
          template_id: promptTemplates.find(
            (item: PromptTemplateType) =>
              item.template_name === selectedSidebarItem
          )?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      await getPromptTemplates();
      setEditMode(!editMode);
    } catch (error) {
      console.error("Error change prompt template - " + error);
    }
  };

  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.sidebarWrapper}>
        <button
          className={style.createNewAccessLevelButton}
          onClick={openCreateNewPromptTemplate}
          style={{ borderColor: state.store.config.colorPrimary }}
        >
          Create new Prompt Template
        </button>

        {promptTemplates
          .filter(
            (item: PromptTemplateType) =>
              item.id === state.store.template_in_use
          )
          .map((item: PromptTemplateType, index: number) => (
            <span
              key={index}
              style={
                selectedSidebarItem === item.template_name
                  ? {
                      marginBottom: 10,
                      marginTop: 10,
                      borderTopColor: "#d3d3d345",
                      borderBottomColor: "#d3d3d345",
                      borderTopWidth: 2,
                      borderBottomWidth: 2,
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      borderRightColor: state.store.config.colorPrimary,
                      color: state.store.config.colorPrimary,
                    }
                  : {
                      marginBottom: 10,
                      marginTop: 10,
                      borderTopColor: "#d3d3d345",
                      borderBottomColor: "#d3d3d345",
                      borderTopWidth: 2,
                      borderBottomWidth: 2,
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                    }
              }
              className={
                selectedSidebarItem === item.template_name
                  ? style.sidebarItemSelected
                  : style.sidebarItem
              }
              onClick={() => setSelectedSidebarItem(item.template_name)}
            >
              Template in use: <br />
              {item.template_name}
            </span>
          ))}
        {promptTemplates
          .filter(
            (item: PromptTemplateType) =>
              item.id !== state.store.template_in_use
          )
          .map((item: PromptTemplateType, index: number) => (
            <span
              key={index}
              className={
                selectedSidebarItem === item.template_name
                  ? style.sidebarItemSelected
                  : style.sidebarItem
              }
              style={
                selectedSidebarItem === item.template_name
                  ? {
                      color: state.store.config.colorPrimary,
                      borderRightColor: state.store.config.colorPrimary,
                    }
                  : {}
              }
              onClick={() => setSelectedSidebarItem(item.template_name)}
            >
              {item.template_name}
            </span>
          ))}
      </div>

      <div
        className={style.chatWrapper}
        style={{ justifyContent: "flex-start", gap: 20 }}
      >
        {selectedSidebarItem === "create" && (
          <>
            <label>Write Prompt-template name</label>
            <textarea
              id="template-name"
              value={
                selectedSidebarItem === "create"
                  ? templateName
                  : promptTemplates.find(
                      (item: PromptTemplateType) =>
                        item.template_name === selectedSidebarItem
                    )?.template_name
              }
              onChange={(e) => setTemplateName(e.currentTarget.value)}
              rows={1}
              style={{
                width: "80%",
                resize: "none",
                borderRadius: 20,
                padding: 15,
                borderColor: state.store.config.colorPrimary,
                borderWidth: 2,
              }}
            ></textarea>
            <label>Write your Prompt-template</label>
            <textarea
              value={
                selectedSidebarItem === "create"
                  ? template
                  : promptTemplates.find(
                      (item: PromptTemplateType) =>
                        item.template_name === selectedSidebarItem
                    )?.template_string
              }
              onChange={(e) => setTemplate(e.currentTarget.value)}
              rows={20}
              style={{
                width: "80%",
                resize: "none",
                borderRadius: 20,
                padding: 15,
                borderColor: state.store.config.colorPrimary,
                borderWidth: 2,
              }}
            ></textarea>

            <button
              disabled={!template || !templateName}
              className={style.createNewTemplateButton}
              onClick={createNewPromptTemplate}
              style={
                !template || !templateName
                  ? {
                      color: state.store.config.colorPrimary,
                      borderColor: state.store.config.colorPrimary,
                    }
                  : { backgroundColor: state.store.config.colorPrimary }
              }
            >
              Submit
            </button>
          </>
        )}
        {selectedSidebarItem !== "create" && (
          <>
            {editMode && <label>Write Prompt-template name</label>}
            <textarea
              id="template-name"
              value={
                editMode
                  ? templateName
                  : promptTemplates.find(
                      (item: PromptTemplateType) =>
                        item.template_name === selectedSidebarItem
                    )?.template_name
              }
              onChange={(e) => setTemplateName(e.currentTarget.value)}
              rows={1}
              style={{
                width: "80%",
                resize: "none",
                borderRadius: 20,
                padding: 15,
                borderColor: state.store.config.colorPrimary,
                borderWidth: 2,
              }}
            ></textarea>
            {editMode && <label>Write your Prompt-template</label>}
            <textarea
              value={
                editMode
                  ? template
                  : promptTemplates.find(
                      (item: PromptTemplateType) =>
                        item.template_name === selectedSidebarItem
                    )?.template_string
              }
              onChange={(e) => setTemplate(e.currentTarget.value)}
              rows={20}
              style={{
                width: "80%",
                resize: "none",
                borderRadius: 20,
                padding: 15,
                borderColor: state.store.config.colorPrimary,
                borderWidth: 2,
              }}
            ></textarea>
            <div className={style.promptTemplateButtonsWrapper}>
              {!editMode ? (
                <>
                  <button
                    className={style.createNewTemplateButton}
                    onClick={switchChangePromptTemplate}
                    style={{ backgroundColor: state.store.config.colorPrimary }}
                  >
                    Edit
                  </button>

                  {promptTemplates.find(
                    (item: PromptTemplateType) =>
                      item.template_name === selectedSidebarItem
                  )?.id === state.store.template_in_use ? (
                    <button
                      className={
                        promptTemplates.find(
                          (item: PromptTemplateType) =>
                            item.template_name === selectedSidebarItem
                        )?.id === state.store.template_in_use
                          ? style.editPromptTemplateCancelButton
                          : style.createNewTemplateButton
                      }
                      onClick={deactivatePromptTemplate}
                      style={{
                        borderColor: state.store.config.colorPrimary,
                        color: state.store.config.colorPrimary,
                      }}
                    >
                      Deactivate
                    </button>
                  ) : (
                    <button
                      className={
                        promptTemplates.find(
                          (item: PromptTemplateType) =>
                            item.template_name === selectedSidebarItem
                        )?.id === state.store.template_in_use
                          ? style.editPromptTemplateCancelButton
                          : style.createNewTemplateButton
                      }
                      style={{
                        backgroundColor: state.store.config.colorPrimary,
                      }}
                      onClick={activatePromptTemplate}
                    >
                      Activate
                    </button>
                  )}
                </>
              ) : (
                <div className={style.changeTemplateButtonsWrapper}>
                  <button
                    className={style.editPromptTemplateCancelButton}
                    onClick={() => setEditMode(!editMode)}
                    style={{
                      borderColor: state.store.config.colorPrimary,
                      color: state.store.config.colorPrimary,
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={style.createNewTemplateButton}
                    onClick={changePromptTemplate}
                    style={{ backgroundColor: state.store.config.colorPrimary }}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PromptTemplates;
